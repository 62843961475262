'use client';

import Slider from 'react-slick';
import { FC, Key, useState, useEffect } from 'react';

import { Box } from '@mui/material';

import Image from '../../../../components/image';
import { API } from '../../../../helpers/api';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

export const AddsCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const [allPrograms, setAllPrograms] = useState<any>([]);
  const settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: !isMobile ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3 * 1000,
  };
  const { combinedCarouselBanner1 }: any = block;
  const position = (item: any) => {
    if (item.position === 'start') return 'fex-start';
    if (item.position === 'center') return 'center';
    if (item.position === 'end') return 'fex-end';
    return 'center';
  };

  const getAllPrograms = async () => {
    const { data } = await API({
      url: '/ad-program',
      method: 'GET',
      params: { offset: 0 },
    });
    setAllPrograms(data.payload);
    console.log('data', data.payload);
  };

  useEffect(() => {
    getAllPrograms();
  }, []);

  const programs = allPrograms.filter((program: any) => program.size === '245x368');

  return (
    <Box
      sx={{
        '& .slick-slide': {
          direction: 'rtl',
          mb: '20px',
          mr: '10px',
          width: '368px!important',
          height: '245px',
        },
        '& .slick-slider': {
          '& .slick-dots': {
            position: 'absolute',
            bottom: block?.settings?.isAlternativeDesign ? '100px' : '0px',
            // right: '40%',
            // width: '150px',
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: block?.settings?.dotsColor,
                },
              },
            },
            '& li': {
              '& button': {
                width: '20%',
                '&::before': {
                  color: block?.settings?.dotsColor,
                },
              },
            },
          },
        },
      }}
    >
      <Slider {...settings}>
        {programs?.map((item: any, index: Key) => (
          <Image
            key={index}
            src={`https://drive.b24online.com/public-assets/${item.image.folder}/${item.image.filename}`}
            sx={{ width: '100%', height: '100%', borderRadius: '10px' }}
          />
        ))}
      </Slider>
    </Box>
  );
};

import { Box, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import ArrowIcon from 'src/assets/icons/happy-gift/arrowIcon';
import DoneIcon from 'src/assets/icons/happy-gift/doneIcon';
import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { getContentValueFromProps } from '../../utils';

const StepBannerItem = ({
  icon,
  text,
  number,
  length,
}: {
  icon: any;
  text: string;
  number: number | string;
  length: number;
}) => (
  <Box display="flex" flexDirection="row" alignItems="flex-end" gap={1}>
    <Box display="flex" alignItems="center" flexDirection="row">
      <Stack direction="column" alignItems="center">
        <Box>
          {number === length ? (
            <DoneIcon width={60} />
          ) : (
            <Typography color="white" fontSize="44px" fontWeight="light">
              {number}
            </Typography>
          )}
        </Box>
        <Stack
          spacing={1}
          alignItems="center"
          border="3px solid white"
          borderRadius={2}
          p={3}
          maxWidth={120}
          height={180}
          sx={{
            transition: 'transform 0.3s ease',
            '&:hover': { transform: 'scale(1.1)' },
          }}
        >
          <Iconify icon={icon} sx={{ width: '100%', height: '50px', color: 'white' }} />
          <Typography textAlign="center" color="white">
            {text}
          </Typography>
        </Stack>
      </Stack>
    </Box>
    {number === length ? (
      ''
    ) : (
      <Box mb={4}>
        <ArrowIcon width={50} />
      </Box>
    )}
  </Box>
);

const CombinedStepBanner: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const [content, setContent] = useState('center');
  const { combinedStepBanner }: any = block || [];
  const { mainTitle }: any = block || '';

  useEffect(() => {
    if (getFieldValue('settings.contentAlign') === 'end') {
      setContent('flex-end');
    }
    if (getFieldValue('settings.contentAlign') === 'start') {
      setContent('flex-start');
    }
    if (getFieldValue('settings.contentAlign') === 'center') {
      setContent('center');
    }
  }, [getFieldValue('settings.contentAlign')]);

  return (
    <Box
      bgcolor={block?.settings?.backgroundColor || '#EE7432'}
      dir="rtl"
      p={3}
      display="flex"
      alignItems="center"
      flexDirection="column"
      borderRadius={2}
    >
      <Typography
        color="primary.contrastText"
        className={block?.settings?.contentClasses}
        sx={{
          direction: block?.settings?.forceRtl ? 'rtl' : '',
          color: block?.settings?.contentTextColor,
          fontSize: block?.settings?.contentFontSize,
          fontStyle: block?.settings?.contentFontStyle,
          fontWeight: block?.settings?.contentFontWeight,
          textDecoration: block?.settings?.contentTextDecoration,
          textAlign: content,
          width: '100%',
        }}
      >
        {mainTitle}
      </Typography>

      <Stack direction="row" gap={1} flexWrap="wrap" justifyContent="center">
        {combinedStepBanner?.map((step: any, idx: number) => (
          <Box flexDirection="row" display="flex" alignItems="center">
            <StepBannerItem
              icon={step.icon}
              text={step.title}
              number={idx + 1}
              length={combinedStepBanner.length}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default CombinedStepBanner;

import { FC } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SanitizeHTML from 'src/utils/sanitize';

import Image from 'src/components/image';

import LineIcon from '../../../../assets/icons/happy-gift/lineIcon';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

// =====================================================================

const ShopBanner4: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const backgroundImage = '/assets/images/happy-gift/backgrounds/blue_background.png';
  const bannerImage = '/assets/images/happy-gift/banners/coffee_title.png';
  const title = 'הנמכרים ביותר';
  const contentText =
    'זיכוי כספי בשווי 100 ש"ח להזמנה מתפריט המסעדה בית קפה עם אוכל ישראלי שיעשה לכם שמח בלב ובבטן בלב כיכר דיזינגוף';
  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        padding: 1,
      }}
    >
      <Grid
        container
        spacing={2}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        px={2}
        py={2}
      >
        <Grid item xs={6} alignItems="center">
          <Box sx={{ position: 'relative', width: '90%' }}>
            <Image src={bannerImage} sx={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '50%',
          }}
        >
          <Stack direction="column" alignItems="end" gap={2}>
            <Typography
              sx={{
                fontWeight: 600,
                color: 'secondary.contrastText',
                fontSize: '46px',
                // mb: 2,
              }}
            >
              {title}
            </Typography>
            <LineIcon color="#5D21EC" />
            <Typography
              sx={{
                textAlign: 'end',
                color: 'secondary.contrastText',
                fontSize: '20px',
              }}
            >
              <SanitizeHTML html={contentText} />
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: '5px',
                fontWeight: 600,
                color: 'black',
                bgcolor: 'secondary.contrastText',
                '&:hover': {
                  bgcolor: 'secondary.contrastText',
                  color: 'black',
                },
              }}
            >
              לפרטים נוספים
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
export default ShopBanner4;

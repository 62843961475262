import { Box, Button, Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import React, { useCallback, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Iconify from '../../../../../../components/iconify';
import Label from '../../../../../../components/label';
import Statistics from './statistics';
import { PersonalInformationTab } from '../personal-information/personal-information-tab';
import { FamilyTab } from '../personal-information/family-tab';
import { NotPaidTable } from './not-paid-table';
import { PaidTable } from './paid-table';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { useTranslate } from '../../../../../../locales';
import { useResponsive } from '../../../../../../hooks/use-responsive';

type AccountTab = {
  value: string;
  label: string;
};

const data_paid_1 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_1 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
];

const data_paid_2 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_2 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: ' עבר תוקף התשלום',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: ' עבר תוקף התשלום',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
];

const data_paid_3 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '4',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '5',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '6',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '7',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '8',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_3 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'עבר תוקף התשלום',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
  {
    id: '4',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
  {
    id: '4',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'עבר תוקף התשלום',
  },
];

export const AssetsTab = ({ block }: ComponentTypeProps) => {
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const [currentTabFirst, setCurrentTabFirst] = useState('notPaid');
  const [currentTabSecond, setCurrentTabSecond] = useState('notPaid');
  const [currentTabThird, setCurrentTabThird] = useState('notPaid');
  const TABS: AccountTab[] = [
    {
      value: 'notPaid',
      label: 'Not paid',
    },
    {
      value: 'paid',
      label: 'Have been paid',
    },
  ];

  const handleChangeTabFirst = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabFirst(newValue);
  }, []);

  const handleChangeTabSecond = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabSecond(newValue);
  }, []);

  const handleChangeTabThird = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabThird(newValue);
  }, []);

  const notPaidTabItem = Object.prototype.hasOwnProperty.call(block, 'notPaidTabItem')
    ? block?.notPaidTabItem
    : true;
  const paidTabItem = Object.prototype.hasOwnProperty.call(block, 'paidTabItem')
    ? block?.paidTabItem
    : true;

  const activeTabNames: string[] = [];

  if (notPaidTabItem) activeTabNames.push('notPaid');
  if (paidTabItem) activeTabNames.push('paid');

  return (
    <Stack gap={2}>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Iconify icon="prime:home" color="#000080" width="50px" />
            <Stack gap={1}>
              <Typography sx={{ fontWeight: 600 }}>ביאליק 31 רמת גן</Typography>
              <Stack direction="row" gap={1}>
                <Typography>דירה 3</Typography>
                <li style={{ width: '5px' }} />
                <Typography>מספר נכס 5456322</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Label color="info">מגורים</Label>
                <Label color="info">הדירה בבעלותך</Label>
              </Stack>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>פרטי חשבון</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מוטי אברמוב</Typography>
                <Typography sx={{ fontWeight: 600 }}>שם מחזיק</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>בעלים</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר חשבון חוזה</Typography>
                <Typography sx={{ fontWeight: 600 }}>9873987573</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>איש קשר בנכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
                <Typography sx={{ fontWeight: 600 }}>050-7654322</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>כתובת למשלוח</Typography>
                <Typography sx={{ fontWeight: 600 }}>ביאליק 31</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מיקוד</Typography>
                <Typography sx={{ fontWeight: 600 }}>12854</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>תאריך אכלוס</Typography>
                <Typography sx={{ fontWeight: 600 }}>11.07.17</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>תאריך עזיבת נכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>11.07.23</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>זיהוי מקרקעין</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.8} xs={12}>
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>חלקה</Typography>
                  <Typography sx={{ fontWeight: 600 }}>432</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>גוש</Typography>
                  <Typography sx={{ fontWeight: 600 }}>6123</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>אזור</Typography>
                  <Typography sx={{ fontWeight: 600 }}>ג</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>גודל הנכס</Typography>
                  <Typography sx={{ fontWeight: 600 }}>65</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={0.2}
              xs={12}
              sx={{
                borderRight: '1px solid',
                borderColor: 'rgb(217, 217, 217)',
              }}
            />
            <Grid item md={8.8} xs={12} sx={{ px: !isMobile ? '20px' : 0 }}>
              <Stack direction={isMobile ? 'column' : 'row'}>
                <Statistics
                  title=""
                  data={[
                    { label: t('Periodic billing'), value: 3044 },
                    { label: t('Discount amount'), value: 2000 },
                    { label: t('God. keeping'), value: 43913 },
                    { label: t('Fee'), value: 50043 },
                  ]}
                />
                <Stack width={isMobile ? 1 : 0.5} padding="10px">
                  <Typography sx={{ fontWeight: 600 }}>ממה מורכבת הארנונה שלך?</Typography>
                  <Typography>
                    אנחנו כאן כדי להסביר ולשקף לך כמה שיותר על מה אתם משלמים, להלן פירוט החיוב כולל
                    האגרות ההנחות, והחיוב התקופתי שלכם. רוצים לחשוב איך אפשר לחסוך בעולם הארנונה?
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    mt={2}
                    sx={{ '&:hover': { color: '#000080' }, cursor: 'pointer' }}
                  >
                    <Typography>זכאות להנחה בארנונה</Typography>
                    <Iconify icon="ep:arrow-left-bold" />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>שוברים לנכס זה</Typography>
            <Stack
              direction={isMobile ? 'column-reverse' : 'row'}
              width={1}
              justifyContent="space-between"
            >
              <Tabs value={currentTabFirst} onChange={handleChangeTabFirst}>
                {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
                  (tab: AccountTab) => (
                    <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
                  )
                )}
              </Tabs>
              <Stack>
                <TextField
                  label="חיפוש לפי מספר שובר"
                  InputProps={{
                    endAdornment: <Iconify icon="ic:twotone-search" />,
                  }}
                />
              </Stack>
            </Stack>
            {currentTabFirst === 'notPaid' && notPaidTabItem && (
              <NotPaidTable block={block} dataNotPaid={data_not_paid_1} />
            )}

            {currentTabFirst === 'paid' && paidTabItem && (
              <PaidTable block={block} dataPaid={data_paid_1} />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack direction={isMobile ? 'column' : 'row'} width={1} justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={2}>
              <Iconify icon="prime:home" color="#000080" width="50px" />
              <Stack gap={1}>
                <Typography sx={{ fontWeight: 600 }}>ביאליק 31 רמת גן</Typography>
                <Stack direction="row" gap={1}>
                  <Typography>דירה 3</Typography>
                  <li style={{ width: '5px' }} />
                  <Typography>מספר נכס 654654</Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <Label color="info">מגורים</Label>
                  <Label color="info">הדירה בבעלותך</Label>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>יתרה לתשלום</Typography>
              <Typography sx={{ textAlign: 'center' }}>876 ש``ח</Typography>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  bgcolor: '#0059d2',
                  borderRadius: '25px',
                  px: '40px',
                }}
              >
                מעבר לתשלום
              </Button>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>פרטי חשבון</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מוטי אברמוב</Typography>
                <Typography sx={{ fontWeight: 600 }}>שם מחזיק</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>בעלים</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר חשבון חוזה</Typography>
                <Typography sx={{ fontWeight: 600 }}>9873987573</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>איש קשר בנכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
                <Typography sx={{ fontWeight: 600 }}>050-7654322</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>כתובת למשלוח</Typography>
                <Typography sx={{ fontWeight: 600 }}>ביאליק 31</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מיקוד</Typography>
                <Typography sx={{ fontWeight: 600 }}>12854</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>תאריך אכלוס</Typography>
                <Typography sx={{ fontWeight: 600 }}>11.07.17</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>תאריך עזיבת נכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>11.07.23</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>זיהוי מקרקעין</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.8} xs={12}>
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>חלקה</Typography>
                  <Typography sx={{ fontWeight: 600 }}>432</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>גוש</Typography>
                  <Typography sx={{ fontWeight: 600 }}>6123</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>אזור</Typography>
                  <Typography sx={{ fontWeight: 600 }}>ג</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>גודל הנכס</Typography>
                  <Typography sx={{ fontWeight: 600 }}>65</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={0.2}
              xs={12}
              sx={{
                borderRight: '1px solid',
                borderColor: 'rgb(217, 217, 217)',
              }}
            />
            <Grid item md={8.8} xs={12} sx={{ px: !isMobile ? '20px' : 0 }}>
              <Stack direction={isMobile ? 'column' : 'row'}>
                <Statistics
                  title=""
                  data={[
                    { label: t('Periodic billing'), value: 3044 },
                    { label: t('Discount amount'), value: 2000 },
                    { label: t('God. keeping'), value: 43913 },
                    { label: t('Fee'), value: 50043 },
                  ]}
                />
                <Stack width={isMobile ? 1 : 0.5} padding="10px">
                  <Typography sx={{ fontWeight: 600 }}>ממה מורכבת הארנונה שלך?</Typography>
                  <Typography>
                    אנחנו כאן כדי להסביר ולשקף לך כמה שיותר על מה אתם משלמים, להלן פירוט החיוב כולל
                    האגרות ההנחות, והחיוב התקופתי שלכם. רוצים לחשוב איך אפשר לחסוך בעולם הארנונה?
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    mt={2}
                    sx={{ '&:hover': { color: '#000080' }, cursor: 'pointer' }}
                  >
                    <Typography>זכאות להנחה בארנונה</Typography>
                    <Iconify icon="ep:arrow-left-bold" />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>שוברים לנכס זה</Typography>
            <Stack
              direction={isMobile ? 'column-reverse' : 'row'}
              width={1}
              justifyContent="space-between"
            >
              <Tabs value={currentTabSecond} onChange={handleChangeTabSecond}>
                {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
                  (tab: AccountTab) => (
                    <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
                  )
                )}
              </Tabs>
              <Stack>
                <TextField
                  label="חיפוש לפי מספר שובר"
                  InputProps={{
                    endAdornment: <Iconify icon="ic:twotone-search" />,
                  }}
                />
              </Stack>
            </Stack>
            {currentTabSecond === 'notPaid' && notPaidTabItem && (
              <NotPaidTable block={block} dataNotPaid={data_not_paid_2} />
            )}

            {currentTabSecond === 'paid' && paidTabItem && (
              <PaidTable block={block} dataPaid={data_paid_2} />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Iconify icon="prime:home" color="#000080" width="50px" />
            <Stack gap={1}>
              <Typography sx={{ fontWeight: 600 }}>ביאליק 31 רמת גן</Typography>
              <Stack direction="row" gap={1}>
                <Typography>דירה 3</Typography>
                <li style={{ width: '5px' }} />
                <Typography>מספר נכס 123452</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Label color="info">מגורים</Label>
                <Label color="info">הדירה בבעלותך</Label>
              </Stack>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>פרטי חשבון</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מוטי אברמוב</Typography>
                <Typography sx={{ fontWeight: 600 }}>שם מחזיק</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>בעלים</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר חשבון חוזה</Typography>
                <Typography sx={{ fontWeight: 600 }}>9873987573</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>איש קשר בנכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
                <Typography sx={{ fontWeight: 600 }}>050-7654322</Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>כתובת למשלוח</Typography>
                <Typography sx={{ fontWeight: 600 }}>ביאליק 31</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מיקוד</Typography>
                <Typography sx={{ fontWeight: 600 }}>12854</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>תאריך אכלוס</Typography>
                <Typography sx={{ fontWeight: 600 }}>11.07.17</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>תאריך עזיבת נכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>11.07.23</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>זיהוי מקרקעין</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.8} xs={12}>
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>חלקה</Typography>
                  <Typography sx={{ fontWeight: 600 }}>432</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>גוש</Typography>
                  <Typography sx={{ fontWeight: 600 }}>6123</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>אזור</Typography>
                  <Typography sx={{ fontWeight: 600 }}>ג</Typography>
                </Grid>
                <Grid item md={5.9} xs={5.9}>
                  <Typography>גודל הנכס</Typography>
                  <Typography sx={{ fontWeight: 600 }}>65</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={0.2}
              xs={12}
              sx={{
                borderRight: '1px solid',
                borderColor: 'rgb(217, 217, 217)',
              }}
            />
            <Grid item md={8.8} xs={12} sx={{ px: !isMobile ? '20px' : 0 }}>
              <Stack direction={isMobile ? 'column' : 'row'}>
                <Statistics
                  title=""
                  data={[
                    { label: t('Periodic billing'), value: 3044 },
                    { label: t('Discount amount'), value: 2000 },
                    { label: t('God. keeping'), value: 43913 },
                    { label: t('Fee'), value: 50043 },
                  ]}
                />
                <Stack width={isMobile ? 1 : 0.5} padding="10px">
                  <Typography sx={{ fontWeight: 600 }}>ממה מורכבת הארנונה שלך?</Typography>
                  <Typography>
                    אנחנו כאן כדי להסביר ולשקף לך כמה שיותר על מה אתם משלמים, להלן פירוט החיוב כולל
                    האגרות ההנחות, והחיוב התקופתי שלכם. רוצים לחשוב איך אפשר לחסוך בעולם הארנונה?
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    mt={2}
                    sx={{ '&:hover': { color: '#000080' }, cursor: 'pointer' }}
                  >
                    <Typography>זכאות להנחה בארנונה</Typography>
                    <Iconify icon="ep:arrow-left-bold" />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>שוברים לנכס זה</Typography>
            <Stack
              direction={isMobile ? 'column-reverse' : 'row'}
              width={1}
              justifyContent="space-between"
            >
              <Tabs value={currentTabThird} onChange={handleChangeTabThird}>
                {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
                  (tab: AccountTab) => (
                    <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
                  )
                )}
              </Tabs>
              <Stack>
                <TextField
                  label="חיפוש לפי מספר שובר"
                  InputProps={{
                    endAdornment: <Iconify icon="ic:twotone-search" />,
                  }}
                />
              </Stack>
            </Stack>
            {currentTabThird === 'notPaid' && notPaidTabItem && (
              <NotPaidTable block={block} dataNotPaid={data_not_paid_3} />
            )}

            {currentTabThird === 'paid' && paidTabItem && (
              <PaidTable block={block} dataPaid={data_paid_3} />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

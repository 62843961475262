import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useState, useEffect, useCallback, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, Dialog, TextField, Typography } from '@mui/material';

import ReportsBlock from './reports-block';
import { useTranslate } from '../../../../locales';
import { IReport } from '../../../../types/reports';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import { useBoolean } from '../../../../hooks/use-boolean';
import { Ib2bTableFilterValue } from '../../../../types/b2b';
import { fileManagerDelete } from '../../../../api/upload-file';
import { usePopover } from '../../../../components/custom-popover';
import { useGetActivityByDomain } from '../../../../api/activities';
import { useGetCustomers } from '../../../../redux/slices/customers';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useTable, TableHeadCustom } from '../../../../components/table';
import { useCopyToClipboard } from '../../../../hooks/use-copy-to-clipboard';
import { getReports, deleteReport, useGetReports } from '../../../../api/reports';
import { useAuthContext } from '../../../../auth/hooks';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: any;
  onSelectRow: VoidFunction;
  onDeleteRow: Function;
};

export default function ListingReports({ block }: ComponentTypeProps) {
  const { user } = useAuthContext();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const defaultFilters: { search: string } = {
    search: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const { t } = useTranslate();
  const [reports, setReports] = useState<IReport[]>([]);
  const { reportsCount } = useGetReports({
    limit,
    offset: (page - 1) * limit,
    search: filters.search,
  });
  const fetchReports = () => {
    getReports({
      limit,
      offset: (page - 1) * limit,
      search: filters.search,
    }).then((response: { payload: SetStateAction<IReport[]> }) => {
      if (response) {
        setReports(response?.payload);
      } else setReports([]);
    });
  };

  useEffect(() => {
    fetchReports();
  }, [limit, page, filters]);

  const LIMIT = 5;
  const TABLE_HEAD = [
    { id: 'date', label: t('Date'), width: 250 },
    { id: 'name', label: t('Name of document'), width: 400 },
  ];
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [edit, onEdit] = useState(false);
  const table = useTable();
  const { activity } = useGetActivityByDomain();
  const { customers } = useGetCustomers(activity.account.merchant.id);
  const deleteItem = (id: string, fileId: string) =>
    deleteReport(id).then((response) => {
      if (response.success) {
        fileManagerDelete([`${fileId}.pdf`], user?.id);
      }
    });

  const handleChangePage = (event: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const handleFilterName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilters('search', event.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    handleFilters('search', '');
  }, []);

  const handleOpenDialog = (report: any) => {
    setSelectedReport(report);
    setOpenDialog(true);
  };

  const handleEditRow = (report: any) => {
    onEdit(true);
    setSelectedReport(report);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedReport(null);
  };

  const handleDeleteConfirmed = () => {
    if (selectedReport) {
      deleteItem(selectedReport.id, selectedReport.fileId)
        .then(() => {
          handleCloseDialog();
          fetchReports();
        })
        .catch((e) => {
          console.error('Error deleting report:', e);
        });
    }
  };

  return (
    <>
      {edit && selectedReport ? (
        <>
          <Stack alignItems="flex-end">
            <IconButton
              onClick={() => {
                setSelectedReport(null);
                onEdit(false);
              }}
            >
              <Iconify icon="ep:arrow-left-bold" />
            </IconButton>
          </Stack>
          <ReportsBlock selectedReport={selectedReport} customersProps={customers} />
        </>
      ) : (
        <Stack alignItems="center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              sx={{
                width: 1,
                fontWeight: 600,
                fontSize: '18px',
                textAlign: 'center',
              }}
            >
              {t('Reports list')}
            </Typography>
            <Stack direction="row" gap={2}>
              <DatePicker label={t('Start')} />
              <DatePicker label={t('End')} />
              <TextField
                value={filters.search}
                placeholder={t('search')}
                onChange={handleFilterName}
                InputProps={{
                  endAdornment: filters.search && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch} edge="end">
                        <Iconify icon="iconamoon:close" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </LocalizationProvider>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ width: '100%' }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={reports.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      reports.map((row) => row.id)
                    )
                  }
                />
                <TableBody>
                  {reports
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <TableListRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleOpenDialog(row)}
                        onEditRow={() => handleEditRow(row)}
                      />
                    ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          <Stack sx={{ mb: 2 }}>
            <Pagination
              onChange={handleChangePage}
              page={page}
              count={Math.ceil(reportsCount / LIMIT) || 1}
              variant="outlined"
              color="primary"
              sx={{ margin: '0 auto' }}
            />
          </Stack>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{t('Confirm Deletion')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Are you sure you want to delete this report?')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                {t('Cancel')}
              </Button>
              <Button onClick={handleDeleteConfirmed} sx={{ color: 'red' }}>
                {t('Delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      )}
    </>
  );
}

function TableListRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }: Props) {
  const { t } = useTranslate();
  const { reportName, createdAt } = row;
  let parsedData;
  try {
    parsedData = typeof row.reportBody === 'string' ? JSON.parse(row.reportBody) : row.reportBody;
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    parsedData = null;
  }

  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();
  const iFrame = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();
  const handleCopy = async () => {
    const url = `https://drive.b24online.com/public-assets/reports-pdfs/${reportName}.pdf`;
    try {
      if (navigator.share) {
        await navigator.share({
          url,
        });
        console.log('Shared successfully!');
      } else {
        await navigator.clipboard.writeText(url);
        console.log('URL copied to clipboard');
      }
      enqueueSnackbar('Copied!', { variant: 'success' });
    } catch (error) {
      console.error('Error copying or sharing URL:', error);
      enqueueSnackbar('Failed to copy!', { variant: 'error' });
    }
  };

  // const handleCopy = useCallback(() => {
  //   const url = `https://drive.b24online.com/public-assets/reports-pdfs/${reportName}.pdf`;
  //   enqueueSnackbar('Copied!');
  //   copy(url);
  // }, [copy, enqueueSnackbar]);

  const openPdf = () => {
    iFrame.onTrue();
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox"> */}
      {/*  <Checkbox checked={selected} onClick={onSelectRow} /> */}
      {/* </TableCell> */}

      <TableCell sx={{ whiteSpace: 'nowrap', width: 300 }}>
        <ListItemText
          primary={format(new Date(createdAt), 'dd MMM yyyy')}
          secondary={format(new Date(createdAt), 'p')}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', width: 300 }}>{reportName}</TableCell>

      <TableCell>
        <Stack direction="row" gap={3}>
          <IconButton>
            <Iconify icon="mdi:edit" color="black" onClick={() => onEditRow()} />
          </IconButton>
          <IconButton>
            <Iconify icon="teenyicons:pdf-outline" color="black" onClick={() => openPdf()} />
          </IconButton>
          <IconButton>
            <Iconify icon="ic:outline-share" color="black" onClick={() => handleCopy()} />
          </IconButton>
          <IconButton onClick={() => onDeleteRow(row.id)}>
            <Iconify icon="tabler:trash" color="red" />
          </IconButton>
        </Stack>
      </TableCell>
      <Dialog open={iFrame.value} onClose={() => iFrame.onFalse()}>
        <DialogContent>
          <Box sx={{ height: '20px' }} />
          <iframe
            src={`https://drive.b24online.com/public-assets/reports-pdfs/${reportName}.pdf`}
            title="Payment"
            width="100%"
            height="500px"
            allow="payment"
            style={{ border: 0 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => iFrame.onFalse()} color="primary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}

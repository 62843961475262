import 'dayjs/locale/he';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Grid, Stack, Button, Popover, MenuItem, IconButton } from '@mui/material';
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import Iconify from '../../components/iconify';
import { useResponsive } from '../../hooks/use-responsive';

dayjs.locale('he');

const timeSlots = [
  {
    date: '2024-07-17',
    time: [
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
    ],
  },
  {
    date: '2024-07-18',
    time: ['09:00', '10:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00'],
  },
  {
    date: '2024-07-19',
    time: ['09:00', '10:00', '11:00', '12:00', '14:00', '15:00', '16:00', '17:00'],
  },
  {
    date: '2024-07-20',
    time: ['09:00', '10:00', '11:00', '12:00', '14:00', '15:00', '16:00', '17:00'],
  },
];

type Props = {
  onSelect: (date: Dayjs, time: string) => void;
};

type ServerDayProps = PickersDayProps<Dayjs> & {
  highlightedDays?: string[];
  availableTimeCount?: { [key: string]: number };
};

const BookingCalendar = ({ onSelect }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [availableTimeCountState, setAvailableTimeCount] = useState<{ [key: string]: number }>({});
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (selectedDate) {
      const dateString = selectedDate.format('YYYY-MM-DD');
      const foundSlot = timeSlots.find((slot) => slot.date === dateString);
      if (foundSlot) {
        setAvailableTimes(foundSlot.time);
      } else {
        setAvailableTimes([]);
      }
    } else {
      setAvailableTimes([]);
    }
  }, [selectedDate]);

  useEffect(() => {
    const countAvailableTimes = () => {
      const count: { [key: string]: number } = {};
      timeSlots.forEach((slot) => {
        count[slot.date] = slot.time.length;
      });
      setAvailableTimeCount(count);
    };
    countAvailableTimes();
  }, []);

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
    setSelectedTime('');
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const onAdornmentClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDisplayCalendar(true);
  };

  const onPopoverClose = () => {
    setDisplayCalendar(false);
    if (selectedDate && selectedTime) {
      onSelect(selectedDate, selectedTime);
    }
  };

  const ServerDay = (props: ServerDayProps) => {
    const {
      highlightedDays = [],
      day,
      outsideCurrentMonth,
      availableTimeCount = {},
      ...other
    } = props;
    const dateString = day.format('YYYY-MM-DD');
    const availableCount = availableTimeCount[dateString] || 0;

    return (
      <Box sx={{ position: 'relative' }}>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day as unknown as PickersDayProps<Dayjs>['day']}
        />
        {availableCount > 0 && (
          <Stack
            alignItems="center"
            sx={{
              position: 'absolute',
              bottom: '-2px',
              width: '100%',
            }}
          >
            <Iconify icon="tabler:point-filled" color="#A584C2" />
          </Stack>
        )}
      </Box>
    );
  };

  const highlightedDays = Object.keys(availableTimeCountState);

  return (
    <Stack alignItems="flex-end">
      <IconButton onClick={onAdornmentClick}>
        <DateRangeIcon />
      </IconButton>
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onPopoverClose}
      >
        <Grid
          container
          spacing={3}
          sx={{ p: 2 }}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid item md={3} xs={12} sx={{ width: !isMobile ? '100px' : '100%' }}>
            <MenuItem value="" disabled>
              בחר זמן
            </MenuItem>
            <Stack
              gap={1}
              sx={{
                maxHeight: '350px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '3px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                  borderRadius: '3px',
                },
              }}
            >
              {availableTimes.length > 0 ? (
                availableTimes.map((time, index) => (
                  <Grid item key={index}>
                    <Button
                      variant="outlined"
                      onClick={() => handleTimeChange(time)}
                      disabled={!availableTimes.includes(time)}
                      sx={{
                        borderColor: time === selectedTime ? 'primary.main' : '',
                        color: time === selectedTime ? 'white' : '',
                        backgroundColor: time === selectedTime ? 'primary.main' : '',
                        textDecoration: !availableTimes.includes(time) ? 'line-through' : 'none',
                        '&:hover': {
                          borderColor: time === selectedTime ? 'primary.main' : '',
                          color: time === selectedTime ? 'white' : '',
                          backgroundColor: time === selectedTime ? 'primary.main' : '',
                        },
                      }}
                    >
                      {time}
                    </Button>
                  </Grid>
                ))
              ) : (
                <Grid item />
              )}
            </Stack>
          </Grid>
          <Grid item md={5} xs={12} sx={{ '& .MuiDialogActions-root': { display: 'none' } }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                defaultValue={dayjs(selectedDate)}
                value={selectedDate}
                onChange={handleDateChange}
                slots={{
                  day: (props) => (
                    <ServerDay
                      {...props}
                      highlightedDays={highlightedDays}
                      availableTimeCount={availableTimeCountState}
                    />
                  ),
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Button onClick={onPopoverClose}>Save</Button>
      </Popover>
    </Stack>
  );
};

export default BookingCalendar;

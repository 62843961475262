import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';

export type leadProps = {
  [key: string]: any;
  botId: number;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  address: string;
  website: string;
  assigned: string | any;
  message: string;
  origin: string;
  phone: string;
  companyName: string;
  type: string;
  statusId: number;
  priority: string[];
  duplicateSendingEmails?: string[];
};

export const addLead = async (data: leadProps) => {
  try {
    if (Object.prototype.hasOwnProperty.call(data, 'assigned')) {
      delete data.assigned;
    }
    const newData = { ...data, botId: `${BOT_ID}`, statusId: 1 };
    const response = await API({
      url: `lead`,
      method: 'POST',
      data: newData,
    });
    return response.data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

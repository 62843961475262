'use client';

import { useMemo, useEffect } from 'react';

import { getCurrentSection } from 'src/utils/sections';

import MainLayout from 'src/layouts/main';
// import { useGetActivitiesByMerchant } from 'src/api/merchant';

import { useContentContext } from 'src/components/content/context/content-context';

import { BlockTypes, SectionTypes } from 'src/types/generator';

import { useRouter } from '../../../routes/hooks';
import GeneratePageBlock from '../generate-page-section';
import { CartProvider } from '../../../contexts/CartContext';

// ----------------------------------------------------------------------

type Props = {
  title?: string | null;
  id?: string;
};

export default function GeneratePageView(params: Props) {
  // const { activity } = useGetActivitiesByMerchant();
  const { title } = params;
  const router = useRouter();
  const { sections } = useContentContext();
  // const {
  //   loginAnonymous,
  // } = useAuthContext();
  const section = useMemo(
    () => getCurrentSection(sections, title ?? SectionTypes.home),
    [sections, title]
  );

  useEffect(() => {
    if (title === 'login') {
      const url = window.location.href;
      const token = new URLSearchParams(url.split('?')[1]).get('token');
      if (token) {
        localStorage.setItem('accessToken', token);
        // loginAnonymous().then(r => router.push('/'));
        router.push('/');
      } else {
        console.error('Token not found in URL');
      }
    }
  }, [title, router]);

  return (
    <MainLayout>
      <CartProvider>
        {section?.data.main
          .filter((block) => ![BlockTypes.footer, BlockTypes.header].includes(block.type))
          .filter((block) => !block.isServiceBlock)
          .map((blockItem, idx) => (
            <GeneratePageBlock key={`page-section-${idx}`} block={blockItem} />
          ))}
      </CartProvider>
    </MainLayout>
  );
}

import Carousel from 'react-slick';
import { FC, useMemo } from 'react';

import { Grid, Container } from '@mui/material';

import { useRouter } from '../../../../routes/hooks';
import PostItemTextAfter from './post-item-text-after';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

type BlogType = {
  content: string;
  enableComments: boolean;
  enableTitle: boolean;
  enableSubtitle: boolean;
  enableLinkText: boolean;
  enablePublicationDate: boolean;
  enableShare: boolean;
  enableViews: boolean;
  enableTextAfterPhoto: boolean;
  mainImage: string;
  mainName: string;
  subTitle: string;
  linkText: string;
  name: string;
  size: string;
  link: string;
};

const Blogs: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');
  const blogs = useMemo(() => {
    const categoriesValue = getFieldValue('blogs');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((blog: BlogType) => ({
        ...blog,
      }));
    }
    return [];
  }, [block?.blogs]);
  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];
  const size = (blog: any) => {
    let blockSize;
    if (blog.size === 'big') blockSize = 6;
    if (blog.size === 'small') blockSize = 3;
    return blockSize;
  };

  return (
    <>
      {isMobile ? (
        <Container maxWidth="xl">
          <Carousel arrows={false} slidesToShow={4} responsive={responsive}>
            {blogs.map((blog, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={size(blog)}
                sx={{ cursor: 'pointer' }}
                onClick={() => router.push(blog?.link)}
              >
                <PostItemTextAfter post={blog} index={index} block={block} />
              </Grid>
            ))}
          </Carousel>
        </Container>
      ) : (
        <Grid container spacing={2} sx={{ direction: 'rtl', justifyContent: 'center' }}>
          {blogs.map((blog, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={size(blog)}
              sx={{ cursor: 'pointer' }}
              onClick={() => router.push(blog?.link)}
            >
              <PostItemTextAfter post={blog} index={index} block={block} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Blogs;

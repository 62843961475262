// import Footer from './footer';
import { useMemo } from 'react';

import Box from '@mui/material/Box';
import { Dialog } from '@mui/material';

import { usePathname } from 'src/routes/hooks';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import { useContentContext } from 'src/components/content/context/content-context';

// eslint-disable-next-line import/no-cycle
import GeneratePageBlock from 'src/sections/generate-page/generate-page-section';

import { BlockType, IOSection, BlockTypes, SectionTypes } from 'src/types/generator';

import { PROJECT_NAME } from '../../config-global';

// import Header from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const pathname = usePathname();
  const rawPath = usePathname();

  const { sections, useBackground, backgroundImage } = useContentContext();
  const { state, dispatch } = useAppContext();
  const { activeDialog } = state;
  let image = '';
  if (Array.isArray(backgroundImage)) {
    if (backgroundImage.length > 0) {
      image = backgroundImage[0];
    }
  } else {
    image = backgroundImage;
  }
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  // TODO: вынести homeSection

  const currentSection = useMemo(() => {
    const pathSplit = rawPath.split('/');
    // const path = pathSplit[pathSplit.length - 2];
    const path = pathSplit[1];
    const byType = sections.find((section) => section.sectionType === path);

    if (byType) return byType;

    const byUrl = sections.find(
      (section) =>
        section.sectionType === SectionTypes.informational && section.url?.replace('/', '') === path
    );

    if (byUrl) return byUrl;

    return sections.find((section) => section.sectionType === SectionTypes.home);
  }, [sections]);

  const headerBlock = useMemo(() => {
    const headers: BlockTypes[] = [BlockTypes.header, BlockTypes.headercitypeople];

    if (currentSection) {
      const block = currentSection?.data.main.find((blockItem: BlockType) =>
        headers.includes(blockItem.type)
      );

      if (block) {
        return { ...block, settings: { ...block.settings, fullSize: true } };
      }
    }
    const homeSection = sections.find(
      (section: IOSection) => section.sectionType === SectionTypes.home
    );
    const homeHeaderBlock = homeSection?.data.main.find((blockItem: BlockType) =>
      headers.includes(blockItem.type)
    );
    return homeHeaderBlock;
  }, [currentSection]);

  const footerBlock = useMemo(() => {
    const footers: BlockTypes[] = [BlockTypes.footer, BlockTypes.footercitypeople];

    if (currentSection) {
      const block = currentSection?.data.main.find((blockItem: BlockType) =>
        footers.includes(blockItem.type)
      );
      if (block) {
        return { ...block, settings: { ...block.settings, fullSize: true } };
      }
    }
    const homeSection = sections.find((section) => section.sectionType === SectionTypes.home);
    const homeFooterBlock = homeSection?.data.main.find((blockItem: BlockType) =>
      footers.includes(blockItem.type)
    );
    return homeFooterBlock;
  }, [currentSection]);

  const homePage = pathname === '/';
  const isCityPeople = useMemo(() => PROJECT_NAME.toLowerCase() === 'citypeople', []);

  const popups = useMemo(() => {
    const popupsSection = sections.find((section) => section.sectionType === SectionTypes.popups);
    if (popupsSection) return popupsSection.data.main;
    return [];
  }, [sections]);

  const mainBlockCommonStyles = {
    flexGrow: 1,
    mt: { xs: 0, md: 0 },
    pb: { xs: 10, md: 0 },
    ...(!homePage && {
      pt: { xs: 0, md: 10 },
    }),
    backgroundImage: useBackground && backgroundImage ? `url(${image})` : '',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  };

  const mainBlockCityPeopleStyles = {
    flexGrow: 1,
    mt: 12,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        mt: { xs: 2, md: 0 },
      }}
    >
      {headerBlock && (
        <GeneratePageBlock
          block={{ ...headerBlock, settings: { ...headerBlock.settings, fullSize: true } }}
        />
      )}

      <Box
        component="main"
        id="121212121212121212"
        sx={isCityPeople ? mainBlockCityPeopleStyles : mainBlockCommonStyles}
      >
        {children}
      </Box>
      {footerBlock && <GeneratePageBlock block={footerBlock} />}
      {!!popups.length &&
        popups.map((popupBlock, idx) => (
          <Dialog
            maxWidth={(popupBlock?.settings?.modalSize as 'xs' | 'sm' | 'md' | 'lg' | 'xl') || 'xs'}
            sx={{ '& .MuiPaper-root': { width: '100%' } }}
            open={popupBlock.modalId === activeDialog}
            key={`dialog_${idx}`}
            onClose={() => setActiveDialog(null)}
          >
            <GeneratePageBlock block={popupBlock} />
          </Dialog>
        ))}
    </Box>
  );
}

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import Box from '@mui/material/Box';

import { useAppContext } from 'src/contexts/AppContext';

import { IVirtualCard } from 'src/types/club-cards';

import { RenderInfoPage } from './render-info-page';
import { BlockType } from '../../../types/page-generator';
import VirtualCardBlock from './wallet-virtual-card-block';
import { useResponsive } from '../../../hooks/use-responsive';

type Props = {
  setOpenProduct: (card: IVirtualCard | null) => void;
  setOpenP2P: (card: IVirtualCard | null) => void;
  openProduct: IVirtualCard | null;
  block: BlockType | undefined;
  openHistory: Function;
  openModal: Function;
  onChangeClubCardInfo: Function;
};

const WalletCardsTab = ({
  setOpenProduct,
  setOpenP2P,
  openProduct,
  block,
  openHistory,
  openModal,
  onChangeClubCardInfo,
}: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const { state } = useAppContext();
  const swiperRef = useRef<SwiperCore | null>(null);
  const {
    state: { virtualCards },
  } = useAppContext();

  const changeOpenProduct = (card: IVirtualCard | null) => {
    setOpenProduct(card);
  };
  const changeOpenP2P = (card: IVirtualCard | null) => {
    setOpenP2P(card);
  };

  const virtualCardsView = virtualCards.concat(state.localVirtualCards);
  return !openProduct ? (
    <>
      {isMobile ? (
        <Box sx={{ '& .swiper-slide': { width: '85vw', height: 'auto', margin: 1 } }}>
          <Box
            sx={{
              width: '100vw',
            }}
          >
            <Swiper
              speed={500}
              slidesPerView="auto"
              loop
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {virtualCardsView.concat(state.localVirtualCards).map((card: IVirtualCard) => (
                <SwiperSlide>
                  <VirtualCardBlock
                    key={card.id}
                    card={card}
                    changeOpenProduct={() => changeOpenProduct(card)}
                    changeOpenP2P={() => changeOpenP2P(card)}
                    block={block}
                    openHistory={openHistory}
                    openModal={openModal}
                    onChangeClubCardInfo={onChangeClubCardInfo}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      ) : (
        <>
          {!block?.settings?.enableSlider ? (
            <Box
              width={1}
              gap={1}
              display="grid"
              gridTemplateColumns={{
                sm: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              sx={{
                pt: 2.5,
                justifyItems: 'center',
              }}
            >
              {virtualCardsView.map((card: IVirtualCard) => (
                <SwiperSlide>
                  <VirtualCardBlock
                    key={card.id}
                    card={card}
                    changeOpenProduct={() => changeOpenProduct(card)}
                    changeOpenP2P={() => changeOpenP2P(card)}
                    block={block}
                    openHistory={openHistory}
                    openModal={openModal}
                    onChangeClubCardInfo={onChangeClubCardInfo}
                  />
                </SwiperSlide>
              ))}
            </Box>
          ) : (
            <Box sx={{ '& .swiper-slide': { width: 'auto', height: 'auto', margin: 1 } }}>
              <Box
                sx={{
                  width: '70vw',
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  loop
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {virtualCardsView.concat(state.localVirtualCards).map((card: IVirtualCard) => (
                    <SwiperSlide>
                      <VirtualCardBlock
                        key={card.id}
                        card={card}
                        changeOpenProduct={() => changeOpenProduct(card)}
                        changeOpenP2P={() => changeOpenP2P(card)}
                        block={block}
                        openHistory={openHistory}
                        openModal={openModal}
                        onChangeClubCardInfo={onChangeClubCardInfo}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  ) : (
    <RenderInfoPage
      card={openProduct}
      returnToAllCards={() => changeOpenProduct(null)}
      block={block}
    />
  );
};

export default WalletCardsTab;

'use client';

import { FC, useState, useCallback, useEffect } from 'react';

// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes
// import { PATH_DASHBOARD as paths } from 'src/routes/paths';
// _mock
// import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { useRouter } from 'next/navigation';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';

//
import AccountGeneral from '../account-general';
import AccountBilling from '../account-billing';
import { useTranslate } from '../../../../../locales';
import AccountSocialLinks from '../account-social-links';
import { useAuthContext } from '../../../../../auth/hooks';
import AccountNotifications from '../account-notifications';
import AccountChangePassword from '../account-change-password';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import {
  _userAbout,
  _userPlans,
  _userPayment,
  _userInvoices,
  _userAddressBook,
} from '../../../../../_mock';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { FamilyCards } from '../../../../../components/family-cards';
import { getCustomerByPhoneOrMail } from '../../../../../api/customers';
import { ICustomerItem } from '../../../../../types/customer-citypeople';
import { useAppContext } from '../../../../../contexts/AppContext';

// ----------------------------------------------------------------------

export type AccountTab = {
  value: string;
  label: string;
  icon: JSX.Element;
};

const TABS: AccountTab[] = [
  {
    value: 'general',
    label: 'General',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'billing',
    label: 'Billing',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
  {
    value: 'notifications',
    label: 'Notifications',
    icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
  },
  {
    value: 'social',
    label: 'Social links',
    icon: <Iconify icon="solar:share-bold" width={24} />,
  },
  {
    value: 'security',
    label: 'Security',
    icon: <Iconify icon="ic:round-vpn-key" width={24} />,
  },
  {
    value: 'family',
    label: 'Family',
    icon: <Iconify icon="icon-park-solid:family" width={24} />,
  },
];

// ----------------------------------------------------------------------

const AccountView: FC<ComponentTypeProps> = ({ block }: ComponentTypeProps) => {
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const [customer, setCustomer] = useState<ICustomerItem[] | any>([]);

  const settings = useSettingsContext();
  const { t } = useTranslate();
  const [currentTab, setCurrentTab] = useState('general');
  const router = useRouter();
  const { logout } = useAuthContext();
  const isMobile = useResponsive('down', 'sm');

  const generalTabItem =
    block && Object.prototype.hasOwnProperty.call(block, 'generalTabItem')
      ? block.generalTabItem
      : true;
  const billingTabItem =
    block && Object.prototype.hasOwnProperty.call(block, 'billingTabItem')
      ? block.billingTabItem
      : true;
  const notificationsTabItem =
    block && Object.prototype.hasOwnProperty.call(block, 'notificationsTabItem')
      ? block.notificationsTabItem
      : true;
  const socialTabItem =
    block && Object.prototype.hasOwnProperty.call(block, 'socialTabItem')
      ? block.socialTabItem
      : true;
  const securityTabItem =
    block && Object.prototype.hasOwnProperty.call(block, 'securityTabItem')
      ? block.securityTabItem
      : true;
  const familyTabItem = Object.prototype.hasOwnProperty.call(block, 'familyTabItem')
    ? block?.familyTabItem
    : true;

  const activeTabNames: string[] = [];

  if (generalTabItem) activeTabNames.push('general');
  if (billingTabItem) activeTabNames.push('billing');
  if (notificationsTabItem) activeTabNames.push('notifications');
  if (socialTabItem) activeTabNames.push('social');
  if (securityTabItem) activeTabNames.push('security');
  if (familyTabItem) activeTabNames.push('family');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const logOut = () => {
    logout();
    router.push('/');
  };

  useEffect(() => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).then((response: any) => {
        setCustomer(response[0]);
      });
    }
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Stack direction="row">
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
            '& .MuiTabs-scrollButtons': {
              width: { xs: '10px' },
              margin: { xs: '5px' },
            },
          }}
        >
          {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
            (tab: AccountTab) => (
              <Tab
                key={tab.value}
                label={`${t(`${tab.label}`)}`}
                icon={tab.icon}
                value={tab.value}
              />
            )
          )}
        </Tabs>
        {!isMobile && (
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={logOut}
            sx={{ mx: 2, mt: 1 }}
          >
            להתנתק
          </Button>
        )}
      </Stack>
      {isMobile && (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={logOut}
          sx={{ mx: 2, mt: 1 }}
        >
          להתנתק
        </Button>
      )}

      {currentTab === 'general' && generalTabItem && <AccountGeneral />}

      {currentTab === 'billing' && billingTabItem && (
        <AccountBilling
          plans={_userPlans}
          cards={_userPayment}
          // @ts-ignore
          invoices={_userInvoices}
          // @ts-ignore
          addressBook={_userAddressBook}
        />
      )}

      {currentTab === 'notifications' && notificationsTabItem && <AccountNotifications />}

      {currentTab === 'social' && socialTabItem && (
        <AccountSocialLinks socialLinks={_userAbout.socialLinks} />
      )}

      {currentTab === 'security' && securityTabItem && <AccountChangePassword />}

      {currentTab === 'family' && familyTabItem && (
        <FamilyCards block={block} customer={customer} />
      )}
    </Container>
  );
};

export default AccountView;

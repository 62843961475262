'use client';

import { FC, useMemo, ReactElement } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { ProductCheckoutSteps, PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';

import { useSettingsContext } from 'src/components/settings';

import CheckoutCart from '../checkout-cart';
import CheckoutSteps from '../checkout-steps';
import { useTranslate } from '../../../locales';
import CheckoutPayment from '../checkout-payment';
import CheckoutOrderError from '../checkout-order-error';
import AddGreetings from '../../addGreetings/AddGreetings';
import CheckoutOrderComplete from '../checkout-order-complete';
import CheckoutBillingAddress from '../checkout-billing-address';
import { BlockType, ComponentTypeProps } from '../../../types/page-generator';
import { getVirtualCards } from '../../../api/clubCards';

const CheckoutView: FC<ComponentTypeProps> = ({ block }) => {
  const settings = useSettingsContext();
  const { t } = useTranslate();
  const { state, dispatch } = useAppContext();

  const checkoutNextStep = () => {
    dispatch({ type: ActionTypes.CHECKOUT_NEXT_STEP, payload: {} });
  };

  const checkoutPrevStep = () => {
    dispatch({ type: ActionTypes.CHECKOUT_PREV_STEP, payload: {} });
  };

  const total = useMemo(() => {
    let result = 0;
    // eslint-disable-next-line
    for (const item of state.cart) {
      result += item.price * item.quantity + Number(item.deliveryOption?.price || 0);
    }

    return result;
  }, [state.cart]);

  const totalSum = useMemo(() => {
    let result = total + (state.checkout.deliveryCost || 0);
    // eslint-disable-next-line no-restricted-syntax
    for (const checkoutCard of state.checkout.usedVirtualCards) {
      result -= checkoutCard.cost;
    }
    // delivery = item.deliveryOptionId && item?.deliveryOption ? +item.deliveryOption.price : 0;
    return result;
  }, [total, state.checkout]);

  const resetBasket = () => {
    dispatch({ type: ActionTypes.CLEAR_CART, payload: {} });
    getVirtualCards(dispatch);
  };

  const isDeliveryEnable = useMemo(() => {
    if (!block || !Object.prototype.hasOwnProperty.call(block, 'isDeliveryEnable')) return true;
    return block.isDeliveryEnable;
  }, [block]);

  const isMessagingActivated = useMemo(() => {
    if (!block || !Object.prototype.hasOwnProperty.call(block, 'isMessagingActivated')) return true;
    return block.isMessagingActivated;
  }, [block]);

  const steps = useMemo(() => {
    const excludedSteps: ProductCheckoutSteps[] = [];
    if (!isDeliveryEnable) excludedSteps.push(ProductCheckoutSteps.Address);
    if (!isMessagingActivated) excludedSteps.push(ProductCheckoutSteps.Greetings);

    return PRODUCT_CHECKOUT_STEPS.filter(
      (step: ProductCheckoutSteps) => !excludedSteps.includes(step)
    );
  }, [isDeliveryEnable, isMessagingActivated]);

  const Cart = useMemo(
    () => (
      <CheckoutCart
        basketData={state.cart}
        checkoutNextStep={checkoutNextStep}
        total={total}
        totalSum={totalSum}
      />
    ),
    [totalSum, block, state.cart]
  );

  const Greetings = useMemo(
    () => (
      <AddGreetings
        cart
        basketData={state.cart}
        block={block || ({} as BlockType)}
        checkoutNextStep={checkoutNextStep}
        checkoutPrevStep={checkoutPrevStep}
      />
    ),
    [block, state.cart]
  );

  const Address = useMemo(
    () => (
      <CheckoutBillingAddress
        checkoutNextStep={checkoutNextStep}
        checkoutPrevStep={checkoutPrevStep}
        totalSum={totalSum}
        total={total}
      />
    ),
    [totalSum, block, state.cart]
  );

  const Payment = useMemo(
    () => (
      <CheckoutPayment
        checkoutPrevStep={checkoutPrevStep}
        total={total}
        totalSum={totalSum}
        block={block || ({} as BlockType)}
      />
    ),
    [totalSum, block, state.cart]
  );

  const stepComponents = useMemo(() => {
    if (!isDeliveryEnable && !isMessagingActivated) {
      return [Cart, Payment];
    }

    if (!isDeliveryEnable) {
      return [Cart, Greetings, Payment];
    }

    if (!isMessagingActivated) {
      return [Cart, Address, Payment];
    }

    return [Cart, Greetings, Address, Payment];
  }, [isDeliveryEnable, isMessagingActivated, state.cart, totalSum, total]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ mb: 10 }}>
      <Typography variant="h4" sx={{ my: { xs: 3, md: 5 } }}>
        {`${t('Cart')}`}
      </Typography>

      <Grid container justifyContent={state.checkout.completed ? 'center' : 'flex-start'}>
        <Grid xs={12} md={8}>
          <CheckoutSteps activeStep={state.checkout.activeStep} steps={steps} />
        </Grid>
      </Grid>

      {state.checkout.isPaymentFailed && (
        <CheckoutOrderError open={state.checkout.isPaymentFailed} onReset={resetBasket} />
      )}

      {state.checkout.completed && !state.checkout.isPaymentFailed ? (
        <CheckoutOrderComplete
          open={state.checkout.completed}
          onReset={resetBasket}
          onDownloadPDF={() => {}}
          block={block}
          basketData={state.cart}
        />
      ) : (
        <>
          {stepComponents.map((component: ReactElement, index: number) =>
            state.checkout.activeStep === index ? component : ''
          )}
        </>
      )}
    </Container>
  );
};

export default CheckoutView;

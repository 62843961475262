/* eslint-disable no-nested-ternary */
import Slider from 'react-slick';
import React, { FC, Key } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { useLocales, useTranslate } from 'src/locales';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { ButtonField } from 'src/types/generator';
import { ComponentTypeProps } from 'src/types/page-generator';

// =====================================================================

const CarouselStandardHorizontalBanner: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslate();
  const { currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-10px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="primary.main" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-10px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="primary.main" />
    </Box>
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: block && block.settings ? (block.settings.playSpeed || 3) * 1000 : 3 * 1000,
    nextArrow: <CustomPrevArrow onClick={() => {}} />,
    prevArrow: <CustomNextArrow onClick={() => {}} />,
  };

  const { combinedBlockCarouselBanner }: any = block;

  return (
    <Box
      sx={{
        '& .slick-slide': {
          height: 'auto',
          direction: 'rtl',
          mb: isMobile ? '50px' : 'auto',
          display: 'grid',
          alignItems: 'end',
        },
        '& .slick-slider': {
          '& .slick-dots': {
            position: 'absolute',
            bottom: '20px',
            right: isMobile ? '0%' : '40%',
            width: isMobile ? '100%' : '150px',
            '& .slick-active': {
              '& button': {
                '&::before': {
                  color: '#27097A !important',
                  fontSize: '16px',
                },
              },
            },
            '& li': {
              '& button': {
                width: '20%',
                '&::before': {
                  color: '#27097A !important',
                  fontSize: '12px',
                },
              },
            },
          },
        },
      }}
    >
      <Slider {...settings}>
        {combinedBlockCarouselBanner?.map((item: any, index: Key) => (
          <Card
            key={index}
            className={item?.bannerClasses}
            sx={{
              width: '100%',
              borderRadius: { md: '20px', xs: '0px' },
              backgroundImage: `url(${item?.backgroundImage})`,
              backgroundSize: 'cover',
              padding: 1,
            }}
          >
            <Grid
              container
              spacing={2}
              flexDirection={
                isMobile ? 'column-reverse' : item?.layout === 'v2' ? 'row-reverse' : 'row'
              }
              alignItems={item?.alignItems}
            >
              <Grid
                item
                xs={isMobile ? 12 : item?.imageSize === 'bigger' ? 5 : 7}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  width: isMobile ? '100%' : '50%',
                }}
              >
                <Stack
                  direction="column"
                  width={1}
                  alignItems={
                    item?.contentAlign === 'start'
                      ? 'flex-start'
                      : item?.contentAlign === 'end'
                        ? 'flex-end'
                        : 'center'
                  }
                >
                  {item?.logoPosition === 'top' && item?.enableLogo ? (
                    <Image
                      className={item?.headingLogoClasses}
                      src={item?.headingLogo}
                      sx={{
                        width: item?.logoSize,
                        mt: 1,
                        '& img': { objectFit: 'contain' },
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {item?.enableHeading && (
                    <Typography
                      className={item?.headingClasses}
                      sx={{
                        fontWeight: item?.headingFontWeight,
                        fontStyle: item?.headingFontStyle,
                        textDecoration: item?.headingTextDecoration,
                        color: item?.headingTextColor,
                        fontSize: item?.headingFontSize,
                        direction: item?.forceRtl ? 'rtl' : undefined,
                        textAlign: item?.headingAlign,
                        width: item?.headingWidth,
                        lineHeight: 1,
                        // mb: 2,
                      }}
                    >
                      {item?.title}
                    </Typography>
                  )}
                  {item?.logoPosition === 'middle' && item?.enableLogo ? (
                    <Image
                      className={item?.headingLogoClasses}
                      src={item?.headingLogo}
                      sx={{
                        width: item?.logoSize,
                        mt: 1,
                        '& img': { objectFit: 'contain' },
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <Typography
                    sx={{
                      direction: currentLang.value === 'he' ? 'rtl' : 'ltr',
                      // color: item?.contentTextColor,
                      // fontSize: item?.contentFontSize,
                      // fontStyle: item?.contentFontStyle,
                      // fontWeight: item?.contentFontWeight,
                      // textDecoration: item?.contentTextDecoration,
                      // textAlign: item?.contentAlign,
                      width: '100%',
                      lineHeight: block?.settings?.lineHeight,
                    }}
                    className={item?.contentClasses}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item?.contentText }} />
                    {/* <SanitizeHTML html={item?.contentText} /> */}
                  </Typography>
                  {item?.logoPosition === 'bottom' && item?.enableLogo ? (
                    <Image
                      className={item?.headingLogoClasses}
                      src={item?.headingLogo}
                      sx={{
                        width: item?.logoSize,
                        mt: 1,
                        '& img': { objectFit: 'contain' },
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {item?.enableLinkText && (
                    <Typography
                      sx={{
                        direction: item?.forceRtl ? 'rtl' : undefined,
                        color: item?.contentTextColor,
                        fontSize: item?.contentFontSize,
                        fontStyle: item?.contentFontStyle,
                        fontWeight: item?.contentFontWeight,
                        textDecoration: 'underline',
                        mb: 1,
                      }}
                    >
                      {item?.linkText}
                    </Typography>
                  )}
                  <Stack direction="row" gap={1} py={1}>
                    {item?.actionButtons.map((actionButton: ButtonField, idx: number) => (
                      // @ts-ignore - проблемы с совместимостью типов, не критично
                      <Button
                        variant={actionButton.variant}
                        color={actionButton.color}
                        size={actionButton.size}
                        key={`actionButton_${idx}`}
                        sx={{ borderRadius: 10, fontWeight: 400 }}
                      >
                        {actionButton.label || `[${t('no label')}]`}
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                item
                xs={isMobile ? 12 : item?.imageSize === 'bigger' ? 7 : 5}
                sx={{ margin: item?.imageSize === 'bigger' ? 'inherit' : '' }}
              >
                <Box sx={{ position: 'relative' }}>
                  <Image className={item?.mainImage} src={item?.mainImage} sx={{ width: '100%' }} />
                  {/* )} */}
                </Box>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Slider>
    </Box>
  );
};
export default CarouselStandardHorizontalBanner;

'use client';

import React, { useState, useEffect } from 'react';

// @mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Menu from '@mui/material/Menu';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { Paper, Dialog } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddCardIcon from '@mui/icons-material/AddCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OutlinedInput from '@mui/material/OutlinedInput';
import PaymentsIcon from '@mui/icons-material/Payments';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// locales
import { useTranslate } from 'src/locales';

import Image from '../image';
import Iconify from '../iconify';
import { CanBeUsed } from './canBeUsed';
import { useAuthContext } from '../../auth/hooks';
import { useBoolean } from '../../hooks/use-boolean';
import { IVirtualCard } from '../../types/club-cards';
import { BlockType } from '../../types/page-generator';
import { useAppContext } from '../../contexts/AppContext';
import { useResponsive } from '../../hooks/use-responsive';
import { useGetActivityByDomain } from '../../api/activities';
import { getCustomerByPhoneOrMail } from '../../api/customers';
import { ICustomerItem } from '../../types/customer-citypeople';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';
import { TabCurrent } from '../../sections/wallet/components/tab-current';
import { TabTransfers } from '../../sections/wallet/components/tab-transfers';
import { TabManagement } from '../../sections/wallet/components/tab-management';
import ModalAddBalance from '../../sections/wallet/components/modal-add-balance';
import { DialogCustomFamily } from '../../sections/wallet/components/dialog-family';
import {
  getVirtualCards,
  addMemberToFamily,
  addVirtualCardToChild,
  getVirtualCardsByCustomerId,
} from '../../api/clubCards';

// types

// ----------------------------------------------------------------------

const styleModalProgram = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const styleModalFamily = {
  minHeight: 300,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
};

const stores = [
  { name: 'Walmart', description: 'Available at all locations.' },
  { name: 'Target', description: 'Accepted in-store and online.' },
  { name: 'Best Buy', description: 'Use your credit card for electronics and more.' },
  { name: 'Costco', description: 'Accepted for membership and purchases.' },
  { name: 'Amazon', description: 'Use your credit card for all online purchases.' },
  // Add more stores as needed
];

type Props = {
  block: BlockType | undefined;
  customer: ICustomerItem | any;
};

export const TeamCards = ({ block, customer }: Props) => {
  const { activity } = useGetActivityByDomain();
  const initialChild = {
    firstName: '',
    lastName: '',
    phone: '',
    virtualCardTypeId: '',
    smbActivityId: activity.id,
    merchantId: 0,
  };
  const initialMember = {
    customerId: '',
  };
  const isMobile = useResponsive('down', 'sm');
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const { t } = useTranslate();
  const addBalance = useBoolean();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalProgram, setOpenModalProgram] = useState(false);
  const [openModalAddFamily, setOpenModalAddFamily] = useState(false);
  const [openAcceptingCardsModal, setOpenAcceptingCardsModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<string>('');

  const [usersVirtualCards, setUsersVirtualCards] = useState<{ [key: string]: IVirtualCard[] }>({});
  // add child
  const [addChild, setAddChild] = useState(initialChild);
  // add Member
  const [addMember, setAddMember] = useState(initialMember);
  const [currentTab, setCurrentTab] = useState(1);
  const [activePeople, setActivePeople] = useState('');
  const [activeCard, setActiveCard] = useState('');
  const [openHistory, setOpenHistory] = useState('');
  const [activeTabHistory, setActiveTabHistory] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [allCustomers, setAllCustomers] = useState<ICustomerItem[] | any>([]);
  const virtualCardsTypes = state.virtualCards;
  const [searchTerm, setSearchTerm] = useState('');
  console.log('customer', customer);
  const fetchCustomer = () => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    getVirtualCards(dispatch);
    const childrenIds =
      (customer && customer?.children?.map((child: { id: string }) => child.id)) || [];
    const familyIds =
      (customer && customer?.family?.map((family: { id: string }) => family.id)) || [];
    if (childrenIds?.length || familyIds?.length) {
      const virtualCards = await Promise.all(
        [...childrenIds, ...familyIds].map((id) => getVirtualCardsByCustomerId(id))
      );

      const virtualCardsByCustomerId: { [key: string]: IVirtualCard[] } = {};
      if (virtualCards?.length) {
        virtualCards.forEach((virtualCard: IVirtualCard[]) => {
          if (!virtualCard.length) return;
          virtualCardsByCustomerId[virtualCard[0].customerId] = virtualCard;
        });
        setUsersVirtualCards(virtualCardsByCustomerId);
      }
    }
  };

  const styleModalStore = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    zIndex: 0,
  };

  const handleStateModalProgram = () => setOpenModalProgram((prev) => !prev);
  const handleStateModalAddFamily = () => {
    setSelectedPerson('');
    setAddMember(initialMember);
    setAddChild(initialChild);
    setOpenModalAddFamily((prev) => !prev);
  };

  const handleOpenAcceptingCardsModal = () => setOpenAcceptingCardsModal(true);
  const handleCloseAcceptingCardsModal = () => {
    setOpenAcceptingCardsModal(false);
  };

  const handleSelectPerson = (event: SelectChangeEvent<string>) => {
    setSelectedPerson(event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSendRequest = () => {
    setOpenModalAddFamily(false);
  };

  const onClickAddChild = async () => {
    if (!addChild.merchantId || !addChild.virtualCardTypeId) return;
    await addVirtualCardToChild({ ...addChild, customerFromCrm: customer.ssoUserId });
    fetchCustomer();
    handleStateModalAddFamily();
    setSearchTerm('');
  };
  const onClickAddMember = async () => {
    if (!addMember.customerId) return;
    await addMemberToFamily({ ...addMember, customerFromCrm: customer.id, redirectUrl: '' });
    fetchCustomer();
    handleStateModalAddFamily();
    setSearchTerm('');
  };

  const open = Boolean(anchorEl);
  const assignedOptions = [{ user: 'Adult' }, { user: 'Child' }];

  const changeHistory = (id: string) => {
    setOpenHistory(id);
    setActiveCard(id);
  };

  const handleChangeTabHistory = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabHistory(newValue);
  };

  const onChangeChildFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddChild((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSearchMemberPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim() === '') {
      setAllCustomers([]);
      return;
    }

    getCustomerByPhoneOrMail(value, '', dispatch)
      .then((response: any) => {
        setAllCustomers(response || []);
      })
      .catch((error) => {
        console.error('Error fetching customer data:', error);
        setAllCustomers([]);
      });
  };
  const onSearchMemberEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim() === '') {
      setAllCustomers([]);
      return;
    }

    getCustomerByPhoneOrMail('', value, dispatch)
      .then((response: any) => {
        setAllCustomers(response || []);
      })
      .catch((error) => {
        console.error('Error fetching customer data:', error);
        setAllCustomers([]);
      });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedCustomerId = event.target.value;
    setAddMember((prev) => ({ ...prev, customerId: selectedCustomerId }));
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      {openHistory === '' ? (
        <Box width={1}>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                addBalance.onTrue();
                handleClose();
              }}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <AddCardIcon sx={{ cursor: 'pointer' }} />
              Add Deposit to card
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                openModal();
              }}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <PaymentsIcon sx={{ cursor: 'pointer' }} />
              Transfer to card
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                changeHistory('1');
              }}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <HistoryIcon sx={{ cursor: 'pointer' }} />
              History
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenAcceptingCardsModal();
              }}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <ListAltIcon sx={{ cursor: 'pointer' }} />
              Can be used for
            </MenuItem>
          </Menu>
          <Card>
            <Stack>
              <CardHeader title={t('Team Cards')} />
              <Stack direction="column" gap={2} p={3}>
                {customer?.family?.map((member: any) => {
                  const virtualCards = usersVirtualCards[member.id] ?? [];
                  const virtualCardsBalance = virtualCards?.reduce(
                    (acc, card) => acc + (card.balance ? parseInt(card.balance, 10) : 0),
                    0
                  );
                  return (
                    <Stack
                      key={member.id}
                      direction="column"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Stack
                            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                            direction="row"
                          >
                            <Avatar
                              alt="Avatar"
                              src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
                              sx={{ width: 30, height: 30 }}
                            />
                            {member.firstName} {member.lastName}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          {virtualCards?.map((card) => (
                            <Stack
                              key={card.id}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{
                                  backgroundColor: card.cardType?.mainImageURL
                                    ? 'transparent'
                                    : 'pink',
                                  width: '50px',
                                  height: '30px',
                                }}
                              >
                                {card.cardType?.mainImageURL && (
                                  <Image
                                    alt="logo wallet"
                                    src={uploadProductsImage(card.cardType?.mainImageURL) || ''}
                                  />
                                )}
                              </Box>
                              <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                                {card.cardType?.name}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: 14, color: '#C684FF' }}
                              >
                                {card.balance ? `${card.balance} ₪` : '0 ₪'}
                              </Typography>
                              <div>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    handleClick(e);
                                    setActiveCard(card.id.toString());
                                    setActivePeople(card.id);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            </Stack>
                          ))}
                          <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
                            <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                              {t('Balance')}:
                            </Typography>
                            <Typography variant="subtitle2" sx={{ fontSize: 14, color: '#C684FF' }}>
                              {virtualCardsBalance} ₪
                            </Typography>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  );
                })}
                {customer?.children?.map((child: any) => {
                  const virtualCards = usersVirtualCards[child.id] ?? [];
                  const virtualCardsBalance = virtualCards?.reduce(
                    (acc, card) => acc + (card.balance ? parseInt(card.balance, 10) : 0),
                    0
                  );
                  return (
                    <Stack
                      key={child.id}
                      direction="column"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Stack
                            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                            direction="row"
                          >
                            <Avatar
                              alt="Avatar"
                              src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
                              sx={{ width: 30, height: 30 }}
                            />
                            {child.firstName} {child.lastName}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          {virtualCards?.map((card) => (
                            <Stack
                              key={card.id}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{
                                  backgroundColor: card.cardType?.mainImageURL
                                    ? 'transparent'
                                    : 'pink',
                                  width: '50px',
                                  height: '30px',
                                }}
                              >
                                {card.cardType?.mainImageURL && (
                                  <Image
                                    alt="logo wallet"
                                    src={uploadProductsImage(card.cardType?.mainImageURL)}
                                  />
                                )}
                              </Box>
                              <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                                {card.cardType?.name}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: 14, color: '#C684FF' }}
                              >
                                {card.balance ? `${card.balance} ₪` : '0 ₪'}
                              </Typography>
                              <div>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    handleClick(e);
                                    setActiveCard(card.id.toString());
                                    setActivePeople(card.id);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </div>
                            </Stack>
                          ))}
                          <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
                            <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                              {t('Balance')}:
                            </Typography>
                            <Typography variant="subtitle2" sx={{ fontSize: 14, color: '#C684FF' }}>
                              {virtualCardsBalance} ₪
                            </Typography>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  );
                })}
                <Box display="flex" justifyContent="end">
                  <Button variant="outlined" onClick={handleStateModalAddFamily}>
                    {t('Add Team')}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Card>

          <Modal
            open={openModalProgram}
            onClose={handleStateModalProgram}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalProgram} gap={2}>
              <Card>
                <Stack direction="column" gap={2} p={3} alignItems="center" justifyContent="center">
                  <Typography variant="subtitle1" sx={{ fontSize: 16 }}>
                    Program 1
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Typography>
                  <Button sx={{ width: '100%' }} onClick={handleStateModalProgram}>
                    Choose Program
                  </Button>
                </Stack>
              </Card>
              <Card>
                <Stack direction="column" gap={2} p={3} alignItems="center" justifyContent="center">
                  <Typography variant="subtitle1" sx={{ fontSize: 16 }}>
                    Program 1
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Typography>
                  <Button onClick={handleStateModalProgram}>Choose Program</Button>
                </Stack>
              </Card>
            </Box>
          </Modal>
          <Modal
            open={openModalAddFamily}
            onClose={handleStateModalAddFamily}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalFamily}>
              <FormControl
                sx={{
                  flexShrink: 0,
                  width: { xs: 1, md: '100%' },
                }}
              >
                <InputLabel>{t('Select Person')}</InputLabel>
                <Select
                  value={selectedPerson}
                  onChange={(e) => handleSelectPerson(e)}
                  input={<OutlinedInput label={t('Select Person')} />}
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 240 },
                    },
                  }}
                >
                  {assignedOptions.map((option) => (
                    <MenuItem key={option.user} value={option.user}>
                      {option.user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedPerson === 'Child' && (
                <>
                  <Typography variant="h5">מלאו את פרטי הילד/ה</Typography>
                  <Stack alignItems="center" justifyContent="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                      sx={{ width: 60, height: 60, position: 'relative' }}
                    >
                      <Avatar
                        alt="Avatar"
                        src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
                        sx={{ width: 60, height: 60 }}
                      />

                      <IconButton
                        color="primary"
                        sx={{ position: 'absolute', bottom: -25, right: 0 }}
                        component="label"
                      >
                        <PhotoCamera />
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          // onChange={handleFileChange}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 1, md: '100%' },
                    }}
                  >
                    <InputLabel>{t('First name')}</InputLabel>
                    <OutlinedInput
                      label={t('First name')}
                      name="firstName"
                      onChange={onChangeChildFields}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 1, md: '100%' },
                    }}
                  >
                    <InputLabel>{t('Last name')}</InputLabel>
                    <OutlinedInput
                      label={t('Last name')}
                      name="lastName"
                      onChange={onChangeChildFields}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 1, md: '100%' },
                    }}
                  >
                    <InputLabel>{t('Phone number')}</InputLabel>
                    <OutlinedInput
                      label={t('Phone number')}
                      name="phone"
                      onChange={onChangeChildFields}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 1, md: '100%' },
                    }}
                  >
                    <InputLabel>{t('Select Card Type')}</InputLabel>
                    <Select
                      value={addChild.virtualCardTypeId}
                      name="virtualCardTypeId"
                      onChange={(e) =>
                        onChangeChildFields(e as React.ChangeEvent<HTMLInputElement>)
                      }
                      input={<OutlinedInput label={t('Select Card Type')} />}
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: 240 },
                        },
                      }}
                    >
                      {virtualCardsTypes.map((cardType) => (
                        <MenuItem key={cardType.id} value={cardType.id}>
                          {cardType.cardType && cardType.cardType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 1, md: '100%' },
                    }}
                  >
                    <InputLabel>{t('Select Merchant')}</InputLabel>
                    <Select
                      name="merchantId"
                      value={addChild?.merchantId}
                      onChange={(e) =>
                        onChangeChildFields(e as React.ChangeEvent<HTMLInputElement>)
                      }
                      input={<OutlinedInput label={t('Select Merchant')} />}
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: 240 },
                        },
                      }}
                    >
                      {customer?.merchants?.length &&
                        customer?.merchants.map((merchant: any) => {
                          console.log('merchant', merchant);
                          return (
                            <MenuItem key={merchant.id} value={merchant.id}>
                              {merchant.title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <Box display="flex" justifyContent="end">
                    <Button variant="outlined" onClick={onClickAddChild}>
                      {t('Save')}
                    </Button>
                  </Box>
                </>
              )}
              {selectedPerson === 'Adult' && (
                <>
                  <Tabs
                    value={currentTab}
                    onChange={handleChangeTab}
                    aria-label="login tabs"
                    centered
                  >
                    <Tab label="Phone" />
                    <Tab label="Email" />
                  </Tabs>
                  {currentTab === 0 ? (
                    <TextField
                      label="Search user by phone"
                      variant="outlined"
                      value={searchTerm}
                      onChange={onSearchMemberPhone}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchTerm && (
                              <IconButton
                                aria-label="clear search"
                                onClick={() => setSearchTerm('')}
                                edge="end"
                              >
                                <Iconify icon="iconamoon:close-fill" />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      label="Search user by email"
                      variant="outlined"
                      value={searchTerm}
                      onChange={onSearchMemberEmail}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchTerm && (
                              <IconButton
                                aria-label="clear search"
                                onClick={() => setSearchTerm('')}
                                edge="end"
                              >
                                <Iconify icon="iconamoon:close-fill" />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <FormControl variant="outlined" fullWidth style={{ marginTop: 16 }}>
                    <InputLabel id="customer-select-label">Select User</InputLabel>
                    <Select
                      labelId="customer-select-label"
                      id="customer-select"
                      value={addMember.customerId}
                      onChange={handleSelectChange}
                      label="Select User"
                    >
                      {allCustomers.length > 0 ? (
                        allCustomers.map((option: any) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.firstName && option?.lastName
                              ? `${option.firstName} ${option.lastName}`
                              : 'No Name'}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled value="">
                          No users found
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <Box display="flex" justifyContent="end">
                    <Button
                      variant="outlined"
                      onClick={onClickAddMember}
                      disabled={!addMember.customerId}
                    >
                      {t('Send Request')}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Modal>
          <Modal open={openAcceptingCardsModal} onClose={handleCloseAcceptingCardsModal}>
            <Box sx={styleModalStore}>
              <CanBeUsed handleCloseAcceptingCardsModal={handleCloseAcceptingCardsModal} />
            </Box>
          </Modal>
          <ModalAddBalance open={addBalance.value} handleClose={addBalance.onFalse} block={block} />
          <Dialog
            maxWidth="xs"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            sx={{ '& .MuiPaper-root': { width: '100%' } }}
          >
            <DialogCustomFamily onClose={() => setModalOpen(false)} block={block} />
          </Dialog>
        </Box>
      ) : (
        <Stack width={1}>
          <Stack width={1} alignItems="flex-end" mb={2} sx={{ cursor: 'pointer' }}>
            <Iconify icon="fluent:ios-arrow-24-filled" onClick={() => setOpenHistory('')} />
          </Stack>
          <Stack gap={2}>
            <Paper
              elevation={3}
              sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <Box
                sx={{ backgroundColor: 'pink', width: '50px', height: '30px', cursor: 'pointer' }}
              />
              <Typography>
                Happy gift {activeCard} / {activePeople}`s card
              </Typography>
              <Tabs value={activeTabHistory} onChange={handleChangeTabHistory} sx={{ p: 1 }}>
                <Tab label={<Typography>עובר ושב</Typography>} />
                <Tab label={<Typography>העבורות</Typography>} />
                <Tab label={<Typography>ניהול</Typography>} />
              </Tabs>
            </Paper>
            <Paper elevation={5}>
              {activeTabHistory === 0 && <TabCurrent />}
              {activeTabHistory === 2 && <TabTransfers />}
              {activeTabHistory === 1 && <TabManagement openModal={openModal} />}
            </Paper>
          </Stack>
        </Stack>
      )}
    </>
  );
};

import NextLink from 'next/link';
import React, { FC, useEffect, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';
import { ButtonColor, ButtonField } from 'src/types/generator';
import { IVirtualCard } from 'src/types/club-cards';
import { getClientListByMerchantId, getHistory } from 'src/api/citypeople';
import { DocumentImage, SupplierHistoryTransaction } from 'src/types/history-city-people';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';

const CLIENT_NAME_TITLE = 'שם לקוח: ';
const PROJECT_NAME_TITLE = 'שם הפרויקט: ';
const ADDRESS_TITLE = 'כתובת: ';
const DATE_TITLE = 'תאריך: ';
const PRICE_TITLE = 'סכום: ';
const APPROVED_ORDER_BTN_TEXT = 'אישור הזמנה';

const SupplierHistoryCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const actionButtons = useMemo(() => getFieldValue('actionButtons'), [block]);
  const { state, dispatch } = useAppContext();
  const { virtualCards, orderHistory, customer } = state;
  const isClient = !state?.customer?.merchants?.length;
  // const isClient = true;
  const modalId = useMemo(() => getFieldValue('openDocumentModalId'), [block?.openDocumentModalId]);
  const isMobile = useResponsive('down', 'sm');
  const selectPhotoModalId = useMemo(
    () => getFieldValue('selectPhotoModalId'),
    [block?.selectPhotoModalId],
  );
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign') ?? false,
    [block?.settings.isAlternativeDesign],
  );

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  const displayedOrderHistory = useMemo(
    () => (state.filterQuery ? state.filteredOrderHistory : state.orderHistory),
    [state.orderHistory],
  );
  const displayClientsList = useMemo(() => state.clientsList, [state.clientsList]);

  useEffect(() => {
    const merchantId =
      Array.isArray(customer?.merchants) && customer?.merchants.length
        ? +customer.merchants[0].id
        : null;

    getHistory(isClient, null, merchantId, dispatch)
      .catch((e) => {
        console.log(`Error during getHistory: ${e}`);
      })
      .finally(() => {
        dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
      });
  }, [state.customer, state.newOrder]);

  useEffect(() => {
    const merchantId =
      Array.isArray(customer?.merchants) && customer?.merchants.length
        ? +customer.merchants[0].id
        : null;

    getClientListByMerchantId(merchantId, dispatch);
  }, [state.customer]);

  return (
    <>
      {block?.settings?.variant === 'clientList' ? (
        <Container maxWidth={isMobile ? 'sm' : 'xl'}>
          <Stack width={1} margin="auto">
            <Stack direction="column" alignItems="center">
              {displayClientsList.map((client: any, id: number) => (
                <Box mt={1} width="100%" key={id}>
                  <Stack width={1} direction="row" justifyContent="space-between">
                    <Typography>
                      שם לקוח:
                      <Typography component="span" fontWeight={600} mx={1}>
                        {client.firstName}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Typography>
                    כתובת:
                    <Typography component="span" fontWeight={600} mx={1}>
                      {client?.apartaments && client?.apartaments[0]?.title}
                    </Typography>
                  </Typography>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item md={12} xs={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{ padding: 0 }}
                        >
                          {block?.settings?.titleOfList}
                        </AccordionSummary>
                        <AccordionDetails>
                          {client?.virtualCards.map((card: IVirtualCard) => (
                            <Stack direction="row" spacing={2}>
                              <Typography>{card?.cardNumber}</Typography>
                              <Typography>{card?.balance} ₪</Typography>
                            </Stack>
                          ))}
                          {/* {vouchers.map((voucher: IVoucher) => ( */}
                          {/*  <Box key={voucher?.id || Math.random()}> */}
                          {/*    <Stack direction="row" spacing={2}> */}
                          {/*      <Typography>{voucher?.name}</Typography> */}
                          {/*      <Typography>{voucher?.price} ₪</Typography> */}
                          {/*    </Stack> */}
                          {/*  </Box> */}
                          {/* ))} */}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                  {block?.settings?.variant === 'default' && (
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" spacing={2} py={2}>
                        {actionButtons.map((actionButton: ButtonField, idx: number) => (
                          // @ts-ignore - проблемы с совместимостью типов, не кртичино
                          <Button
                            variant={actionButton.variant}
                            color={actionButton.color}
                            key={`actionButton_${idx}`}
                            sx={{ borderRadius: 10 }}
                          >
                            {actionButton.label || 'No label'}
                          </Button>
                        ))}
                      </Stack>
                      {/* <Button */}
                      {/*  variant="outlined" */}
                      {/*  color="primary" */}
                      {/*  sx={{ fontSize: 12 }} */}
                      {/*  onClick={() => {}} */}
                      {/* > */}
                      {/*  צלם הזמנת רכש */}
                      {/* </Button> */}
                      {block?.settings?.variant !== 'isExchange' && (
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Iconify
                            icon="material-symbols:print-outline"
                            width={42}
                            height={39}
                            onClick={() => {
                            }}
                          />
                          <Iconify
                            icon="carbon:document-view"
                            width={42}
                            height={39}
                            onClick={() => {
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Box>
              ))}
            </Stack>
          </Stack>
          <Divider sx={{ width: '100%' }} />
        </Container>
      ) : (
        <Container maxWidth={isMobile ? 'sm' : 'xl'}>
          <Stack direction="column" alignItems="center">
            {!orderHistory.length && (
              <Typography
                sx={{ margin: '0 auto' }}
                textAlign="center"
                variant="h6"
                color="secondaryContrastText"
              >
                אין היסטוריה
              </Typography>
            )}
            {displayedOrderHistory.map((item: unknown) => {
              const { creator, amount, order, transactionType } =
                item as SupplierHistoryTransaction;
              const getButtonColor = (smbColor: ButtonColor | null | undefined): ButtonColor => {
                if (!smbColor) return 'primary';
                if (smbColor !== 'success') return smbColor;
                switch (order?.status) {
                  case 'PAID':
                    return 'success';
                  case 'CANCELLED':
                    return 'primary';
                  case 'PENDING':
                    return 'warning';
                  default:
                    return 'error';
                }
              };
              const orderImages = order?.documents
                ? order.documents.map((doc: DocumentImage) => doc.url)
                : [];

              return (
                <Stack direction="row" sx={{ width: '100%', maxWidth: '393px' }}>
                  <Box mt={1} width="100%">
                    {block?.settings?.variant === 'isExchange' ||
                    block?.settings?.variant === 'clientList' ? (
                      <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
                        <Typography>
                          {CLIENT_NAME_TITLE}
                          <Typography component="span" fontWeight={600} mx={1}>
                            {creator?.firstName} {creator?.lastName}
                          </Typography>
                        </Typography>
                        {block?.settings?.variant === 'isExchange' && (
                          <Typography
                            sx={{
                              fontWeight: 1000,
                              color: transactionType === 'DEBIT' ? 'green' : '#00bfff',
                            }}
                          >
                            {transactionType}
                          </Typography>
                        )}
                      </Stack>
                    ) : (
                      <Typography>
                        {CLIENT_NAME_TITLE}
                        <Typography component="span" fontWeight={600} mx={1}>
                          {creator?.firstName} {creator?.lastName}
                        </Typography>
                      </Typography>
                    )}
                    {/* {block?.settings?.variant === 'default' && ( */}
                    {/*  <Stack direction="row" justifyContent="space-between"> */}
                    {/*    <Typography> */}
                    {/*      שם הפרויקט: */}
                    {/*      <Typography component="span" fontWeight={600} mx={1}> */}
                    {/*        {projectName} */}
                    {/*      </Typography> */}
                    {/*    </Typography> */}
                    {/*    <Iconify icon="carbon:document-view" width={25} height={19} onClick={() => {}} /> */}
                    {/*  </Stack> */}
                    {/* )} */}
                    <Typography>
                      {ADDRESS_TITLE}
                      <Typography component="span" fontWeight={600} mx={1}>
                        {creator.address}
                      </Typography>
                    </Typography>
                    {block?.settings?.variant === 'default' ? (
                      <Stack direction="row" spacing={2}>
                        <Typography>
                          {DATE_TITLE}
                          <Typography sx={{ fontWeight: 600 }} component="span" mx={1}>
                            {order?.createdAt
                              ? format(new Date(order.createdAt), 'dd MMM yyyy')
                              : ''}
                          </Typography>
                        </Typography>
                        <Typography component="span">
                          {PRICE_TITLE}
                          <Typography sx={{ fontWeight: 600 }} component="span" mx={1}>
                            {amount} ₪
                          </Typography>
                        </Typography>
                      </Stack>
                    ) : (
                      <>
                        {block?.settings?.variant !== 'clientList' && (
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Typography>
                                {DATE_TITLE}
                                <Typography sx={{ fontWeight: 600 }} component="span" mx={1}>
                                  {order?.createdAt
                                    ? format(new Date(order.createdAt), 'dd MMM yyyy')
                                    : ''}
                                </Typography>
                              </Typography>
                              <Typography component="span">
                                {PRICE_TITLE}
                                <Typography sx={{ fontWeight: 600 }} component="span" mx={1}>
                                  {amount} ₪
                                </Typography>
                              </Typography>
                            </Stack>
                            <Iconify
                              icon="carbon:document-view"
                              width={42}
                              height={39}
                              onClick={() => {
                                if (modalId) {
                                  dispatch({
                                    type: ActionTypes.ADD_ORDER_IMAGES,
                                    payload: orderImages,
                                  });
                                  setActiveDialog(modalId);
                                }
                              }}
                            />
                          </Stack>
                        )}
                        {block?.settings?.variant === 'clientList' && (
                          <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item md={12} xs={12}>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                  sx={{ padding: 0 }}
                                >
                                  {block?.settings?.titleOfList}
                                </AccordionSummary>
                                <AccordionDetails>
                                  {virtualCards.map((card: IVirtualCard) => (
                                    <Grid container>
                                      <Grid item md={8} xs={8}>
                                        <Typography>
                                          {card?.cardType?.name || card?.cardNumber}
                                        </Typography>
                                      </Grid>
                                      <Grid item md={4} xs={4}>
                                        <Typography textAlign="end">{card?.balance} ₪</Typography>
                                      </Grid>
                                    </Grid>
                                  ))}
                                  {/* {vouchers.map((voucher: IVoucher) => ( */}
                                  {/*  <Box key={voucher?.id || Math.random()}> */}
                                  {/*    <Stack direction="row" spacing={2}> */}
                                  {/*      <Typography>{voucher?.name}</Typography> */}
                                  {/*      <Typography>{voucher?.price} ₪</Typography> */}
                                  {/*    </Stack> */}
                                  {/*  </Box> */}
                                  {/* ))} */}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                    {block?.settings?.variant === 'default' && (
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={2} py={2}>
                          {actionButtons.map((actionButton: ButtonField, idx: number) => (
                            // @ts-ignore - проблемы с совместимостью типов, не кртичино
                            <Button
                              component={
                                actionButton?.link.includes('modal') ||
                                actionButton.link.includes('#')
                                  ? Button
                                  : NextLink
                              }
                              variant={actionButton?.variant || 'contained'}
                              color={getButtonColor(actionButton?.color)}
                              size={actionButton?.size || 'small'}
                              key={`actionButton_${idx}`}
                              sx={{
                                borderRadius: 10,
                                textWrap: 'nowrap',
                              }}
                              href={
                                (!actionButton?.link.includes('modal') &&
                                  !actionButton.isDialogToggler &&
                                  !actionButton.link.includes('#') &&
                                  actionButton?.link) ||
                                ''
                              }
                              onClick={() => {
                                if (actionButton.isDialogToggler && actionButton.modalId) {
                                  setActiveDialog(actionButton.modalId);
                                }
                                if (actionButton.link.includes('#')) {
                                  const newLink = actionButton?.link.replace('#', '');
                                  window.location.href = `#${newLink}`;
                                }
                                /* only green button (when orderStatus>PENDING) can select order */
                                /* see getButtonColor func. */
                                if (getButtonColor(actionButton?.color) === 'warning') {
                                  dispatch({
                                    type: ActionTypes.SET_SELECTED_ORDER,
                                    payload: order,
                                  });
                                }
                                dispatch({ type: ActionTypes.SET_UPDATED_ORDER, payload: order });
                              }}
                            >
                              {getButtonColor(actionButton?.color) === 'warning'
                                ? APPROVED_ORDER_BTN_TEXT
                                : actionButton.label || 'No label'}
                            </Button>
                          ))}
                        </Stack>
                        {/* <Button */}
                        {/*  variant="outlined" */}
                        {/*  color="primary" */}
                        {/*  sx={{ fontSize: 12 }} */}
                        {/*  onClick={() => {}} */}
                        {/* > */}
                        {/*  צלם הזמנת רכש */}
                        {/* </Button> */}
                      </Stack>
                    )}
                    <Divider sx={{ width: '100%' }} />
                  </Box>
                  {block?.settings?.variant !== 'isExchange' &&
                    block?.settings?.variant !== 'clientList' && (
                      <Stack direction="column" alignItems="center" spacing={2}>
                        <Iconify
                          icon="material-symbols:print-outline"
                          width={42}
                          height={39}
                          onClick={() => {
                          }}
                        />
                        <Iconify
                          icon="carbon:document-view"
                          width={42}
                          height={39}
                          onClick={() => {
                            if (modalId) {
                              dispatch({
                                type: ActionTypes.ADD_ORDER_IMAGES,
                                payload: orderImages,
                              });
                              setActiveDialog(modalId);
                            }
                          }}
                        />
                      </Stack>
                    )}
                </Stack>
              );
            })}
          </Stack>
        </Container>
      )}
    </>
  );
};

export default SupplierHistoryCityPeople;

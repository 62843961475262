import dayjs from 'dayjs';
import JsPDF from 'jspdf';
import * as Yup from 'yup';
import * as React from 'react';
import moment from 'moment/moment';
import html2canvas from 'html2canvas';
import SignatureCanvas from 'react-signature-canvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';

import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputBase, { InputBaseProps, inputBaseClasses } from '@mui/material/InputBase';
import {
  Box,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  TableContainer,
} from '@mui/material';

import uuidv4 from '../../../../utils/uuidv4';
import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import { BOT_ID } from '../../../../config-global';
import { Ib2bProduct } from '../../../../types/b2b';
import Iconify from '../../../../components/iconify';
import { useGetProducts } from '../../../../api/shop';
import { createReport, createTicket } from '../../../../api/reports';
import { useAuthContext } from '../../../../auth/hooks';
import { UploadBox } from '../../../../components/upload';
import { parseUserForFm } from '../../../../utils/upload';
import { useSnackbar } from '../../../../components/snackbar';
import { useResponsive } from '../../../../hooks/use-responsive';
import { extractPrices } from '../../../../helpers/extractPrices';
import { useGetActivityByDomain } from '../../../../api/activities';
import { useGetCustomers } from '../../../../redux/slices/customers';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { fileManager, fileManagerDelete } from '../../../../api/upload-file';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';

type UploadFile = File & { preview: any };

type block = {
  id: string;
  type: string;
  productId?: string;
  price?: string | number;
  count?: string | number;
  name?: string;
  data?: string;
  text?: string;
};

export type DraggableListItemType = {
  id: string | any;
  type: string;
  dateToFinish?: Date | string;
  assigned?: any;
  status?: string;
  blocks: block[];
  images?: File[];
  text?: string;
  name?: string;
  whose?: string;
  assignedItem?: any;
  signature?: any;
  assignedId?: string;
};

export type FreeTextType = {
  id: string;
  text: string;
};

interface Item {
  id: number;
  images: UploadFile[];
  isPreview: boolean;
  imageUrl: string;
}

const createFile = (imageData: any) => {
  const fakeData = new ArrayBuffer(imageData.size);
  const blob = new Blob([fakeData], { type: imageData.type });

  const file = new File([blob], imageData.name, {
    type: imageData.type,
    lastModified: imageData.lastModified,
  });

  Object.defineProperty(file, 'path', { value: imageData.path });
  Object.defineProperty(file, 'preview', { value: imageData.preview });
  Object.defineProperty(file, 'edit', { value: true });

  return file;
};

export default function ReportsBlock({
  block,
  selectedReport,
  customersProps,
}: ComponentTypeProps) {
  const reportsBody =
    selectedReport && selectedReport?.reportBody ? JSON.parse(selectedReport?.reportBody) : {};
  const { activity } = useGetActivityByDomain();
  const { customers } = useGetCustomers(activity.account.merchant.id);
  const [localCustomers, setLocalCustomers] = useState<any>([]);
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useAppContext();
  const divToPrintRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const deleteHeaderImageCount = useRef(0);
  const hasRunRef = useRef(false);
  const hasRunAssignRef = useRef(false);
  const hasRunSignatureRef = useRef(false);
  const hasRunProductRef = useRef(false);
  const { t } = useTranslate();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      columns: reportsBody ? reportsBody.columns : [{ name: '' }],
      rows: reportsBody ? reportsBody.rows : [{ cells: [''] }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [mainPdfFile, setPdfFile] = useState<any>({});
  const [rightFooter, setRightFooter] = useState(reportsBody ? reportsBody.rightFooter : '');
  const [headerText, setHeaderText] = useState(reportsBody ? reportsBody.headerText : '');
  const [headerImage, setImage] = useState<any>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [leftFooter, setLeftFooter] = useState(reportsBody ? reportsBody.leftFooter : '');
  const isMobile = useResponsive('down', 'sm');
  const [freeText, setFreeText] = useState<FreeTextType[]>([]);
  const [localData, setLocalData] = useState<any>('');
  const defaultArray = [
    {
      id: 'to',
      text: '',
      blocks: [],
      type: 'to',
    },
    {
      id: 'assigned',
      text: '',
      blocks: [],
      type: 'assigned',
      assignedItem: [],
    },
    {
      id: '1',
      type: 'table',
      blocks: [],
      images: [],
    },
    {
      id: '2',
      type: 'assign',
      dateToFinish: '',
      assigned: '',
      status: '',
      blocks: [],
    },
  ];
  const [listItems, setListItems] = useState<DraggableListItemType[]>(defaultArray);
  useEffect(() => {
    if (customersProps !== undefined) {
      setLocalCustomers(customersProps);
    } else {
      setLocalCustomers(customers);
    }
  }, [customers]);
  useEffect(() => {
    if (selectedReport?.assigneeId) {
      const current =
        (customersProps && customersProps.find((c: any) => c.id === selectedReport.assigneeId)) ||
        null;

      setListItems((prevListItems) =>
        prevListItems.map((item) => {
          if (item.id === 'assigned') {
            return {
              ...item,
              id: 'assigned',
              text: `${current?.firstName || ''} ${current?.lastName || ''}`,
              assigned: current || [],
              type: 'assigned',
              blocks: [],
            } as any;
          }
          return item;
        })
      );
    }
  }, [selectedReport, customersProps, setListItems]);

  useEffect(() => {
    if (hasRunAssignRef.current) return;
    setListItems((prevItems) => prevItems.filter((item) => item.type !== 'assign'));
    if (reportsBody?.assigned) {
      const newItems = reportsBody?.assigned.map((report: any) => {
        const currentUser = getCurrentUser(report.assigneeId);
        return {
          id: report.assigneeId,
          assigned: report.assigneeId,
          dateToFinish: moment(report.dateOfFinish),
          type: 'assign',
          status: report.status,
          text: `${currentUser?.firstName} ${currentUser?.lastName}`,
          blocks: [],
        };
      });

      setListItems((prevItems) => [...prevItems, ...newItems]);
    }

    hasRunAssignRef.current = true;
  }, [selectedReport]);

  useEffect(() => {
    if (hasRunRef.current) return;

    if (selectedReport?.reportSignatures) {
      const newItems = selectedReport?.reportSignatures.map((report: any) => ({
        id: report.id,
        type: 'signature',
        whose: report.type,
        signature: report.description,
        blocks: [],
      }));

      setListItems((prevItems) => [...prevItems, ...newItems]);
    }

    hasRunRef.current = true;
  }, [selectedReport]);

  useEffect(() => {
    handleToChange(reportsBody.to || '');
  }, [selectedReport]);

  const { products } = useGetProducts({
    limit: 9999,
    offset: 0,
  });

  const NewSchema = Yup.object({
    assign_assign: Yup.string().required('is required'),
    name: Yup.string().required('is required'),
    products: block?.settings?.enableProducts
      ? Yup.array()
          .of(
            Yup.object({
              id: Yup.string().required('Product ID is required'),
              title: Yup.string().required('Product title is required'),
              text: Yup.string().notRequired(),
              count: Yup.number()
                .min(0, 'Count must be greater than or equal to 0')
                .required('Count is required'),
              price: Yup.number().min(0, 'Price must be greater than or equal to 0').notRequired(),
            })
          )
          .min(1, 'At least one product is required')
      : Yup.array(),
  });

  const defaultValues = {
    assign_assign: '',
    name: '',
    products: [],
  };

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });
  const {
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data: any) => {
    data.reportName = themeName;
    data.authorId = user && user.id;
    data.botId = Number(process.env.NEXT_PUBLIC_BOT_ID);

    const assignedCurrentItem: any = listItems.find((item) => item.id === 'assigned');
    const assigneeId: any = assignedCurrentItem?.assigned?.id as string;
    data.assigneeId = assigneeId;

    data.headerText = headerText;
    const toItem = listItems.find((item) => item.id === 'to');

    const assignedItem = listItems.find((item) => item.id === 'assigned');
    if (toItem) {
      data.to = toItem.text;
    }
    if (assignedItem) {
      data.assigned = assignedItem.text;
    }

    const formattedProductItems = listItems
      .filter((item) => item && item?.type === 'product')
      .flatMap((item) =>
        item.blocks.map((b) => ({
          quantity: b.count ? +b.count : 0,
          price: b.price || 0,
          productId: b.productId,
          description: b.text || '',
        }))
      );

    data.reportProducts = formattedProductItems;

    const signatures = signatureData.map((signature) => ({
      signatureAuthorId: signature.assignedId,
      type: signature.whose.toLowerCase(),
      fileId: signature.fileId,
      description: signature.signature,
    }));

    data.reportSignature = signatures;
    if (rows.length) {
      const rowsWithImages = rows.map((row, index) => {
        const item = items[index];
        return {
          ...row,
          images: item.images,
          imageUrl: item.imageUrl,
        };
      });
      data.rows = rowsWithImages;
    }

    setLocalData(data);
  };

  const savePdf = async () => {
    setLoading(true);
    const element = divToPrintRef.current;
    const footerElement = footerRef.current;
    const headerElement = headerRef.current;

    if (element && footerElement && headerElement) {
      const pdf = new JsPDF('p', 'mm', 'a4');
      const pdfWidth = 210;
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Обработка футера
      const footerCanvas = await html2canvas(footerElement);
      const footerImgData = footerCanvas.toDataURL('image/png');
      const footerImgProps = pdf?.getImageProperties(footerImgData);
      const footerImgHeight = (footerImgProps.height * pdfWidth) / footerImgProps.width;

      // Обработка хедера
      const headerCanvas = await html2canvas(headerElement);
      const headerImgData = headerCanvas.toDataURL('image/png');
      const headerImgProps = pdf?.getImageProperties(headerImgData);
      const headerImgHeight = (headerImgProps.height * pdfWidth) / headerImgProps.width;
      const contentHeight = pdfHeight - footerImgHeight - headerImgHeight;

      const elements = Array.from(element.children) as HTMLElement[];
      let totalHeight = 0;
      let position = headerImgHeight; // Начальная позиция после хедера
      let pageCount = 1;

      pdf.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight);

      const addElementToPdf = async (elem: HTMLElement, addNewPage = false) => {
        const childCanvas = await html2canvas(elem);
        const childImgData = childCanvas.toDataURL('image/png');
        const childImgProps = pdf?.getImageProperties(childImgData);
        const childImgHeight = (childImgProps.height * pdfWidth) / childImgProps.width;

        if (addNewPage) {
          pdf.addPage();
          pdf.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight);
          position = headerImgHeight;
          pageCount += 1;
        }

        if (position + childImgHeight > contentHeight) {
          pdf.addImage(
            footerImgData,
            'PNG',
            0,
            pdfHeight - footerImgHeight,
            pdfWidth,
            footerImgHeight
          );
          pdf.text(`${pageCount} /`, pdfWidth / 2, pdfHeight - 5, { align: 'center' });
          pdf.addPage();
          pdf.addImage(headerImgData, 'PNG', 0, 0, pdfWidth, headerImgHeight);
          position = headerImgHeight;
          pageCount += 1;
        }

        pdf.addImage(childImgData, 'PNG', 0, position, pdfWidth, childImgHeight);
        position += childImgHeight;

        return childImgHeight;
      };

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < elements.length; i++) {
        const elem = elements[i];
        // eslint-disable-next-line no-await-in-loop
        const childCanvas = await html2canvas(elem);
        const childImgProps = pdf?.getImageProperties(childCanvas.toDataURL('image/png'));
        const childImgHeight = (childImgProps.height * pdfWidth) / childImgProps.width;

        if (totalHeight + childImgHeight > contentHeight) {
          if (totalHeight === 0) {
            // eslint-disable-next-line no-await-in-loop
            await addElementToPdf(elem, true);
          } else {
            pdf.addImage(
              footerImgData,
              'PNG',
              0,
              pdfHeight - footerImgHeight,
              pdfWidth,
              footerImgHeight
            );
            pdf.text(`${pageCount} /`, pdfWidth / 2, pdfHeight - 5, { align: 'center' });
            // eslint-disable-next-line no-await-in-loop
            await addElementToPdf(elem, true);
          }
          totalHeight = childImgHeight;
        } else {
          totalHeight += childImgHeight;
          // eslint-disable-next-line no-await-in-loop
          await addElementToPdf(elem);
        }
      }

      pdf.addImage(footerImgData, 'PNG', 0, pdfHeight - footerImgHeight, pdfWidth, footerImgHeight);
      pdf.text(`${pageCount} /`, pdfWidth / 2, pdfHeight - 5, { align: 'center' });

      // @ts-ignore
      const totalPages = pdf.internal.getNumberOfPages();
      // eslint-disable-next-line no-plusplus
      for (let j = 1; j <= totalPages; j++) {
        pdf.setPage(j);
        pdf.text(`${totalPages}`, pdfWidth / 2 + 5, pdfHeight - 5, { align: 'center' });
      }

      const pdfBlob = pdf.output('blob');
      const pdfFile = new File([pdfBlob], themeName ? `${themeName}.pdf` : 'Example.pdf', {
        type: 'application/pdf',
      });
      setPdfFile(pdf);
      const response = await fileManager([pdfFile], 'reports-pdfs', user);
      setLoading(false);

      if (response.message === 'Files uploaded successfully') {
        return response.data[0];
      }

      throw new Error('Failed to upload PDF');
    }

    setLoading(false);
    return null;
  };

  const loadPdf = async () => {
    if (user) {
      if (mainPdfFile) {
        try {
          const uploadedPdfFile = await savePdf();
          const formattedItems = listItems
            .filter((item) => item && item?.type === 'assign')
            .map((item) => ({
              assigneeId: item.assigned || '',
              dateOfFinish: item.dateToFinish || '',
              status: item.status || 'new',
            }));

          const newHeaderImage: any = {
            edit: true,
            path: headerImage.path,
            preview: headerImage.preview,
            lastModified: headerImage.lastModified,
            lastModifiedDate: headerImage.lastModifiedDate,
            name: headerImage.name,
            size: headerImage.size,
            type: headerImage.type,
            webkitRelativePath: headerImage.webkitRelativePath,
          };

          const dataReport: any = {
            assigned: formattedItems,
            headerImage: headerImage ? headerImage.id : '',
            headerImageFile: newHeaderImage && newHeaderImage,
            freeText,
            headerText,
            rightFooter,
            leftFooter,
            fileName: uploadedPdfFile.fileName,
            rows: localData.rows,
            columns: localData.columns,
            to: localData.to,
          };

          localData.reportBody = JSON.stringify(dataReport);
          localData.fileId = uploadedPdfFile.id;

          const assignedUserIds = formattedItems.map((item) => item.assigneeId);

          const ticketBody: any = {};
          ticketBody.title = localData.reportName;
          ticketBody.description = 'Ticket created from report';
          ticketBody.botId = BOT_ID;
          ticketBody.reporterUserId = localData.authorId;
          ticketBody.createdByUserId = localData.authorId;
          ticketBody.assignedUserIds = [];
          ticketBody.statusId = 0;
          const response = await createReport(localData);

          if (response.success === true) {
            // const pdf = new JsPDF(mainPdfFile);
            const pdf = mainPdfFile;
            await createTicket(ticketBody).then((r) => {});
            pdf.save(themeName ? `${themeName}.pdf` : 'Example.pdf');
            enqueueSnackbar('Report created successfully', { variant: 'success' });
          } else {
            await fileManagerDelete([`reports-pdfs/${uploadedPdfFile.fileName}`], user?.id);
            enqueueSnackbar('Error creating report', { variant: 'error' });
            console.error('Report creation failed:', response);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else if (!user) {
      dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
    } else {
      enqueueSnackbar('No PDF file available to process', { variant: 'warning' });
    }
  };

  const addProductFieldBlock = (id: string) => {
    setListItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              blocks: [...item.blocks, { id: String(item.blocks.length + 1), type: 'text' }],
            }
          : item
      )
    );
  };

  const deleteProductFieldBlock = (blockId: string, productId: string) => {
    setListItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === blockId) {
          return {
            ...item,
            blocks: item.blocks.filter((blockField) => blockField.id !== productId),
          };
        }
        return item;
      })
    );
  };

  const handleToChange = (value: string) => {
    setListItems((prevListItems) =>
      prevListItems.map((item) => {
        if (item.id === 'to') {
          return {
            ...item,
            text: value,
          };
        }
        return item;
      })
    );
  };

  const handleAssignChange = (value: any) => {
    setListItems((prevListItems) =>
      prevListItems.map((item) => {
        if (item.id === 'assigned') {
          return {
            ...item,
            text: `${value.firstName || ''} ${value.lastName || ''}`,
            assigned: value,
          };
        }
        return item;
      })
    );
  };

  const handleBlockAssignChange = (id: string, value: any) => {
    const itemIndex = listItems.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      setListItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          text: value?.firstName && value?.lastName ? `${value?.firstName} ${value?.lastName}` : '',
          assigned: value?.id,
        };
        return updatedItems;
      });
    } else {
      setListItems((prevItems) => [
        ...prevItems,
        {
          id,
          text: value?.firstName && value?.lastName ? `${value?.firstName} ${value?.lastName}` : '',
          assigned: value?.id,
          dateToFinish: '',
          status: '',
          type: 'assign',
          blocks: [],
        },
      ]);
    }
  };
  const handleFreeTextChange = (id: string, text: string) => {
    setFreeText((prevItems) => {
      const existingItemIndex = prevItems.findIndex((item) => item.id === id);

      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex] = { ...updatedItems[existingItemIndex], text };
        return updatedItems;
      }
      return [...prevItems, { id: uuidv4(), text }];
    });
  };

  const addFreeTextBlock = () => {
    setFreeText((prevItems) => [...prevItems, { id: uuidv4(), text: '' }]);
  };

  const removeFreeTextBlock = (itemId: string) => {
    setFreeText((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const removeAssignBlock = (id: string) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const removeSignatureBlock = (id: string) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
    setSignatureData((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const removeProductBlock = (id: string) => {
    setListItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const addAssignBlock = () => {
    setListItems((prevItems) => [
      ...prevItems,
      {
        id: uuidv4(),
        type: 'assign',
        blocks: [],
      },
    ]);
    handleClose();
  };

  const addSignatureBlock = () => {
    setListItems((prevItems) => [
      ...prevItems,
      {
        id: uuidv4(),
        type: 'signature',
        whose: 'Client',
        blocks: [],
      },
    ]);
    handleClose();
  };

  const addProductBlock = () => {
    setListItems((prevItems) => [
      ...prevItems,
      {
        id: uuidv4(),
        type: 'product',
        blocks: [{ id: '1', type: 'text', productId: '', price: 0 }],
      },
    ]);
    handleClose();
  };

  const onChangeSelect = (
    event: React.ChangeEvent<{}>,
    value: Ib2bProduct | null,
    blockId: string,
    productId: string
  ) => {
    if (value) {
      setListItems((prevItems) =>
        prevItems.map((item) =>
          item.id === blockId
            ? {
                ...item,
                blocks: item.blocks.map((i) =>
                  i.id === productId
                    ? {
                        ...i,
                        price: extractPrices(value.prices).price,
                        name: value.title,
                        productId: value.id,
                        text: '',
                        count: '',
                      }
                    : i
                ),
              }
            : item
        )
      );
    }
  };

  const handleChangeCount = (
    blockId: string,
    productId: string,
    field: keyof block,
    value: string | number | undefined
  ) => {
    setListItems((prevItems: DraggableListItemType[]) =>
      prevItems.map((item) =>
        item.id === blockId
          ? {
              ...item,
              blocks: item.blocks.map((it) =>
                it.id === productId ? { ...it, [field]: value || '' } : it
              ),
            }
          : item
      )
    );
  };

  const handleChangeText = (
    blockId: string,
    productId: string,
    field: keyof block,
    value: string
  ) => {
    setListItems((prevItems: DraggableListItemType[]) =>
      prevItems.map((item) =>
        item.id === blockId
          ? {
              ...item,
              blocks: item.blocks.map((it) =>
                it.id === productId ? { ...it, [field]: value } : it
              ),
            }
          : item
      )
    );
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tableExists = listItems.some((item) => item && item?.type === 'table');
  const productsExists = listItems.some((item) => item && item?.type === 'product');

  const addTableBlock = () => {
    if (!tableExists) {
      setListItems((prevItems) => [...prevItems, { id: uuidv4(), type: 'table', blocks: [] }]);
    }
    handleClose();
  };

  const [themeName, setThemeName] = useState(selectedReport ? selectedReport?.reportName : '');

  const handleChangeThemeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeName(event.target.value);
  }, []);

  const getFileWithPreview = (fil: File): UploadFile =>
    Object.assign(fil, { preview: URL.createObjectURL(fil) });

  const [items, setItems] = useState<Item[]>([
    { id: 0, images: [], isPreview: false, imageUrl: '' },
  ]);

  useEffect(() => {
    if (reportsBody.rows) {
      const newItems = reportsBody.rows.map((report: any, index: number) => {
        const { fileName } =
          (report.imageUrl &&
            report.imageUrl.data &&
            report.imageUrl &&
            report.imageUrl.data &&
            report.imageUrl.data[0]) ||
          [];
        const newFileName = fileName?.replace(/ /g, '_') || '';
        const imageData = {
          path: newFileName,
          preview:
            (report.imageUrl && report.imageUrl.data && report.imageUrl.data[0].fileName) || '',
          lastModified:
            moment(
              report.imageUrl && report.imageUrl.data && report.imageUrl.data[0].lastModified
            ).valueOf() || '',
          lastModifiedDate: '',
          name: newFileName,
          size: (report.imageUrl && report.imageUrl.data && report.imageUrl.data[0].size) || 0,
          type: 'image/png',
          webkitRelativePath: '',
        };

        const file = createFile(imageData);

        const imageUrls = {
          message: 'Files uploaded successfully',
          data: [
            {
              id: report.imageUrl && report.imageUrl.data && report.imageUrl.data[0].id,
              fileName: file.name,
              size: file.size,
              lastModified: new Date(file.lastModified).toISOString(),
            },
          ],
        };

        return {
          id: index + 1,
          images: [file],
          imageUrl: imageUrls,
          isPreview: true,
        };
      });

      setItems(newItems);
    }

    if (reportsBody.headerImageFile) {
      const { path } = reportsBody.headerImageFile;
      const newPath = path?.replace(/ /g, '_') || '';
      const imageData = {
        path: newPath,
        preview: reportsBody.headerImageFile.preview,
        lastModified: '',
        lastModifiedDate: '',
        name: newPath,
        size: '',
        type: 'image/png',
        webkitRelativePath: '',
      };

      const file = createFile(imageData);

      setImage(file);
    }
    if (reportsBody.freeText) {
      setFreeText(reportsBody.freeText);
    }
  }, []);

  const handleLoad = async (files: File[], rowIndex: number) => {
    const newUser = user;
    if (newUser && !newUser.email) newUser.email = 'migirov1@gmail.com';
    else if (newUser && newUser.email) newUser.email = user.email;
    const [firstFile] = files;
    if (firstFile) {
      const modFirstFile = await getFileWithPreview(firstFile);
      const filesWithPreview = files.map((f) => getFileWithPreview(f));
      const filesLinks = await Promise.all(
        filesWithPreview.map((modFile) =>
          fileManager(files, 'reports-images', parseUserForFm(newUser))
        )
      );
      setItems((prevItems) =>
        prevItems.map((item, index) =>
          index === rowIndex
            ? {
                ...item,
                images: item.images ? [...item.images, modFirstFile] : [modFirstFile],
                imageUrl: filesLinks && filesLinks[0],
                isPreview: true,
              }
            : item
        )
      );
    }
  };

  const handleLoadLogo = async (files: File[]) => {
    const newUser = user;
    if (newUser && !newUser.email) newUser.email = 'migirov1@gmail.com';
    else if (newUser && newUser.email) newUser.email = user.email;
    if (files.length) {
      try {
        const data = await fileManager(files, 'reports-images', parseUserForFm(newUser));
        const { fileName } = data.data[0];
        const filesLinks = `${fileName}`;
        const [firstFile] = files;
        if (firstFile) {
          const modFirstFile = await getFileWithPreview(firstFile);
          const modFile: any = modFirstFile;
          modFile.id = data.data[0].id;
          setImage(modFile);
        }
        setImageUrl(filesLinks);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };

  const handleRemoveImage = (rowIndex: number, imageIndex: number, image: any) => {
    // fileManagerDelete([image.imageUrl.data[0].fileName]).then((response) => {
    //   if (response.success === true) {
    setItems((prevItems) =>
      prevItems.map((item, index) =>
        index === rowIndex
          ? { ...item, images: item.images.filter((_, i) => i !== imageIndex) }
          : item
      )
    );
    setImageUrl('');
    // }
    // });
  };

  const sigCanvas = useRef<{ [key: string]: SignatureCanvas | null }>({});
  const [signatureData, setSignatureData] = useState<
    {
      id: string;
      signature: string;
      whose: string;
      name: string;
      fileName?: string;
      fileId?: string;
      assignedId?: string;
    }[]
  >([]);
  useEffect(() => {
    if (hasRunSignatureRef.current) return;

    if (selectedReport?.reportSignatures && localCustomers) {
      const newItems = selectedReport?.reportSignatures.map((report: any) => {
        const currentUser = getCurrentUser(report.signatureAuthorId);

        return {
          id: report.id,
          type: 'signature',
          whose: report.type,
          signature: report.description,
          name: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '',
          assignedId: report.signatureAuthorId,
          email: currentUser?.email || '',
          fileId: report.fileId,
        };
      });

      setSignatureData((prevItems) => [...prevItems, ...newItems]);
    }

    hasRunSignatureRef.current = true;
  }, [selectedReport, localCustomers]);

  useEffect(() => {
    if (hasRunProductRef.current) return;
    if (selectedReport?.reportProducts) {
      const blocks = selectedReport.reportProducts.map((product: any) => ({
        id: uuidv4(),
        type: 'text',
        productId: product.productId,
        price: product.price,
        count: product.quantity,
        text: product.description,
      }));

      const newItem = {
        id: uuidv4(),
        type: 'product',
        blocks,
      };

      setListItems((prevItems) => [...prevItems, newItem]);
    }
    hasRunProductRef.current = true;
  }, [selectedReport]);

  useEffect(() => {
    signatureData.forEach((item) => {
      const canvas = sigCanvas.current[item.id];
      if (canvas) {
        canvas.fromDataURL(item.signature);
      }
    });
  }, [signatureData]);

  const clearSignature = (
    item:
      | {
          id: string;
          signature: string;
          whose: string;
          name: string;
          fileName?: string;
          fileId?: string;
        }
      | any
  ) => {
    const canvas = sigCanvas.current[item.id];
    if (canvas) {
      // fileManagerDelete([item.fileName || '']).then((response) => {
      //   if (response.success === true) {
      canvas.clear();
      updateSignatureData({ id: item.id }, { signature: '' });
      updateSignatureData({ id: item.id }, { fileName: '' });
      updateSignatureData({ id: item.id }, { fileId: '' });
      // }
      // });
    }
  };

  const updateSignatureData = (
    item: { id: string },
    updates: Partial<{
      id: string;
      signature: string;
      whose: string;
      name: string;
      fileName?: string;
      fileId?: string;
      assignedId?: string;
    }>
  ) => {
    setSignatureData((prevItems) => {
      const foundIndex = prevItems.findIndex((i) => i.id === item.id);
      if (foundIndex !== -1) {
        const updatedItem = {
          ...prevItems[foundIndex],
          ...updates,
        };
        const updatedItems = [
          ...prevItems.slice(0, foundIndex),
          updatedItem,
          ...prevItems.slice(foundIndex + 1),
        ];
        return updatedItems;
      }

      return [
        ...prevItems,
        {
          id: item.id,
          signature: updates.signature ?? '',
          name: updates.name ?? '',
          whose: updates.whose ?? 'Client',
          assignedId: updates.assignedId,
        },
      ];
    });
  };

  // useEffect(() => {
  //   signatureData.forEach((item) => {
  //     const canvas = sigCanvas.current[item.id];
  //     if (canvas) {
  //       canvas.fromDataURL(item.signature);
  //     }
  //   });
  // }, [signatureData]);

  const defaultValueWhoseSignature = useCallback(
    (id: string) => {
      const current = listItems.find((list) => list.id === id);
      const name =
        user?.id && current && current?.whose === 'Client'
          ? `${user?.firstName} ${user?.lastName}`
          : 'sdsdsdsd';
      return name;
    },
    [listItems]
  );

  const updateListItems = (
    item: DraggableListItemType | any,
    updates: Partial<DraggableListItemType>
  ) => {
    setListItems((prevItems: any) => {
      const foundIndex = prevItems.findIndex((ite: { id: string }) => ite.id === item.id);
      if (foundIndex !== -1) {
        const updatedItem = {
          ...prevItems[foundIndex],
          ...updates,
        };
        const updatedItems = [
          ...prevItems.slice(0, foundIndex),
          updatedItem,
          ...prevItems.slice(foundIndex + 1),
        ];
        return updatedItems;
      }
      return [
        ...prevItems,
        {
          id: item.id,
          ...updates,
          signature: prevItems[foundIndex]?.signature || '',
          name: prevItems[foundIndex]?.name || '',
          whose: prevItems[foundIndex]?.whose || 'Client',
        },
      ];
    });
  };

  function base64ToBlob(base64String: string, mimeType: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  const handleMouseLeave = (item: DraggableListItemType) => {
    const canvas = sigCanvas.current?.[item.id];
    if (canvas && !canvas.isEmpty()) {
      const signature = canvas.toDataURL('image/png');
      const blob = base64ToBlob(signature.split(',')[1], 'image/png');
      const formData = new FormData();
      formData.append('file', blob, 'signature.png');

      const files: any = [];
      formData.forEach((value, key) => {
        if (value instanceof Blob) {
          files.push(value);
        }
      });

      const newUser = user;
      if (newUser && !newUser.email) newUser.email = 'migirov1@gmail.com';
      else if (newUser && newUser.email) newUser.email = user.email;

      fileManager(files, 'reports-signatures', parseUserForFm(newUser)).then((response) => {
        if (response.message === 'Files uploaded successfully') {
          updateSignatureData(item, { signature });
          updateSignatureData(item, { fileName: response.data[0].fileName });
          updateSignatureData(item, { fileId: response.data[0].id });
          updateListItems(item, { signature });
        }
      });
    }
  };

  const handleWhoseSignatureChange = (item: DraggableListItemType, whoseValue: string) => {
    updateSignatureData(item, { whose: whoseValue || 'Client' });
    updateListItems(item, { whose: whoseValue || 'Client' });
  };

  const handleNameSignatureChange = (item: DraggableListItemType, nameValue: any) => {
    const name = `${nameValue.firstName} ${nameValue.lastName}`;
    updateSignatureData(item, { name });
    updateSignatureData(item, { assignedId: nameValue.id });
    updateListItems(item, { name });
    updateListItems(item, { assignedId: nameValue.id });
  };

  const {
    fields: columns,
    append: appendColumn,
    remove: removeColumn,
  } = useFieldArray({
    control,
    name: 'columns',
  });

  const {
    fields: rows,
    append: appendRow,
    remove: removeRow,
    update: updateRow,
  } = useFieldArray({
    control,
    name: 'rows',
  });

  const addRow = () => {
    appendRow({ cells: columns.map(() => '') });
  };

  const addTableRow = () => {
    addRow();
    setItems((prevItems) => [
      ...prevItems,
      { id: prevItems.length + 1, images: [], isPreview: false, imageUrl: '' },
    ]);
  };

  const removeTableRow = (rowIndex: number | number[] | undefined) => {
    removeRow(rowIndex);
    setItems((prevItems) => prevItems.filter((_, index) => index !== rowIndex));
  };

  const addColumn = () => {
    appendColumn({ name: '' });
    rows.forEach((row: any, index) => {
      const updatedCells = [...row.cells, ''];
      updateRow(index, { ...row, cells: updatedCells });
    });
  };

  const removeColumnAndCells = (index: any) => {
    removeColumn(index);
    rows.forEach((row: any, rowIndex) => {
      const updatedCells = row.cells.filter((_: any, cellIndex: any) => cellIndex !== index);
      updateRow(rowIndex, { ...row, cells: updatedCells });
    });
  };

  const handleChange = (id: string, field: string, value: any) => {
    const itemIndex = listItems.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      setListItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = { ...updatedItems[itemIndex], [field]: value };
        return updatedItems;
      });
    } else {
      setListItems((prevItems) => [
        ...prevItems,
        {
          id,
          [field]: value,
          dateToFinish: '',
          assigned: '',
          status: '',
          type: 'assign',
          blocks: [],
        },
      ]);
    }
  };

  const calculateTotal = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      const price = parseFloat(it.price as string) || 0;
      return sum + count * price;
    }, 0);

  const calculateTotalCount = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      return sum + count;
    }, 0);

  const itemTo = listItems.find((it) => it.id === 'to');
  const itemToText = itemTo ? itemTo.text : '';
  const itemAssign = listItems.find((it) => it.id === 'assigned');
  const itemAssignText = itemAssign ? itemAssign.text : '';

  const assignedItems = listItems.filter((it) => it.type === 'assign');

  const productItems = listItems.filter((it) => it.type === 'product');

  const tableItems = listItems.filter((it) => it.type === 'table');
  const signatureItems = signatureData;

  const deleteImage = (image: any) => {
    deleteHeaderImageCount.current += 1;

    if (deleteHeaderImageCount.current === 1 && selectedReport) {
      return;
    }
    // fileManagerDelete([image.name]).then((response) => {
    //   if (response.success === true) {
    setImage('');
    // }
    // });
  };

  const getSelectedCustomer = (itemId: string) => {
    const signature = signatureData.find((data) => data.id === itemId);
    return localCustomers?.find((c: any) => c.id === signature?.assignedId) || null;
  };
  const getCurrentUser = useCallback(
    (itemId: string) =>
      (customersProps && customersProps.find((c: any) => c.id === itemId)) || null,
    [customersProps]
  );
  const getCurrentProduct = (itemId: string | undefined) =>
    (products && products.find((c: any) => c.id === itemId)) || null;
  return (
    <Stack alignItems="center" sx={{ position: 'relative', zIndex: 10 }}>
      <Stack alignItems="center" gap={2} sx={{ mb: 2, pb: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" width={1} gap={1} mt={2} alignItems="center">
            <TextField
              fullWidth
              label={t('Text')}
              name="text"
              value={headerText}
              onChange={(e) => setHeaderText(e.target.value)}
            />
            <>
              {headerImage ? (
                <Box sx={{ position: 'relative', maxWidth: '20%' }}>
                  {!headerImage.edit ? (
                    <Image src={URL.createObjectURL(headerImage)} />
                  ) : (
                    <Image
                      src={`https://drive.b24online.com/public-assets/reports-images/${headerImage?.path}`}
                      width={1}
                    />
                  )}
                  <IconButton
                    size="small"
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => deleteImage(headerImage)}
                  >
                    <Iconify icon="simple-line-icons:close" width={20} />
                  </IconButton>
                </Box>
              ) : (
                <UploadBox
                  multiple={false}
                  thumbnail
                  onDrop={(files) => handleLoadLogo(files)}
                  onUpload={() => console.info('ON UPLOAD')}
                />
              )}
            </>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack alignItems="center">
              <Image src={block?.settings?.uploadLogo} sx={{ width: '100px' }} />
              <Stack
                width={1}
                direction="row"
                sx={{ alignItems: 'start', justifyContent: 'space-between' }}
              >
                <Stack gap={1}>
                  <RHFTextField
                    name="to"
                    value={listItems.find((item) => item.id === 'to')?.text || ''}
                    sx={{ width: isMobile ? '100%' : '100%' }}
                    label={t('To')}
                    onChange={(e) => handleToChange(e.target.value)}
                  />
                  <Autocomplete
                    options={Array.isArray(localCustomers) ? localCustomers : []}
                    getOptionLabel={(option) =>
                      `${option?.firstName} ${option?.lastName} (${option?.email})`
                    }
                    onChange={(event, newValue) => {
                      handleAssignChange(newValue || []);
                    }}
                    value={
                      localCustomers?.find(
                        (с: { id: string }) =>
                          с.id === listItems.find((item) => item.id === 'assigned')?.assigned?.id
                      ) || null
                    }
                    renderInput={(params) => {
                      const foundItem = listItems.find((list) => list.id === 'assigned');
                      const assign = foundItem?.assigned || {};

                      const isError =
                        !assign || (typeof assign === 'object' && Object.keys(assign).length === 0);

                      return (
                        <TextField
                          {...params}
                          label={t('Assign')}
                          sx={{ width: '100%' }}
                          error={isError}
                          helperText={
                            isError ? (
                              <Typography sx={{ fontSize: '12px', mt: '-10px' }}>
                                {t('Assign is required')}
                              </Typography>
                            ) : (
                              ''
                            )
                          }
                        />
                      );
                    }}
                  />
                  {freeText.map((it) => (
                    <Stack
                      key={it.id}
                      direction="row"
                      gap={1}
                      sx={{ position: 'relative', width: '100%' }}
                    >
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: '-15px',
                          left: '-13px',
                        }}
                        onClick={() => removeFreeTextBlock(it.id)}
                      >
                        <Iconify icon="mingcute:close-line" width={20} />
                      </IconButton>
                      <RHFTextField
                        name={`freeText_${it.id}`}
                        key={it.id}
                        value={it.text}
                        sx={{ width: isMobile ? '100%' : '100%' }}
                        onChange={(e) => handleFreeTextChange(it.id, e.target.value)}
                      />
                    </Stack>
                  ))}
                  <IconButton sx={{ width: '20%' }}>
                    <Iconify
                      sx={{ color: 'green' }}
                      icon="ic:twotone-plus"
                      onClick={() => addFreeTextBlock()}
                    />
                  </IconButton>
                </Stack>
                <DatePicker
                  name="dateNow"
                  sx={{ width: isMobile ? '30%' : '30%' }}
                  defaultValue={dayjs()}
                  disabled
                />
              </Stack>
              <TicketsInputName
                name="name"
                placeholder={t('Enter the theme')}
                value={themeName}
                onChange={handleChangeThemeName}
                error={themeName === ''}
              />
              {themeName === '' && (
                <Typography sx={{ fontSize: '12px', color: 'red' }}>
                  {`${t('Name is required')}`}
                </Typography>
              )}
            </Stack>

            {listItems.map((item) => (
              <Stack key={item.id}>
                {item && item?.type === 'table' && (
                  <Box>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack spacing={2} mb={2} direction="row">
                        <IconButton onClick={addColumn}>
                          <Iconify color="green" icon="material-symbols:view-column" />
                        </IconButton>
                        <IconButton onClick={addTableRow}>
                          <Iconify color="green" icon="fluent:row-triple-24-filled" />
                        </IconButton>
                        <Button
                          sx={{ color: 'red' }}
                          onClick={() => {
                            setListItems(listItems.filter((i) => i.id !== item.id));
                            reset({
                              columns: [],
                              rows: [],
                            });
                          }}
                        >
                          {t('Remove')}
                        </Button>
                      </Stack>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        {t('Table')}
                      </Typography>
                    </Stack>
                    <Box sx={isMobile ? { overflowX: 'auto', width: '90vw' } : {}}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, colIndex) => (
                                <TableCell
                                  key={colIndex}
                                  sx={{ minWidth: { md: '150px', xs: '50px' } }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{ position: 'relative' }}
                                  >
                                    <Controller
                                      control={control}
                                      // @ts-ignore
                                      name={`columns[${colIndex}].name`}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          label={`${t('Column')} ${colIndex + 1}`}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )}
                                    />
                                    <IconButton
                                      size="small"
                                      sx={{ position: 'absolute', top: '-15px', left: '-13px' }}
                                      onClick={() => removeColumnAndCells(colIndex)}
                                    >
                                      <Iconify icon="mingcute:close-line" width={20} />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row: any, rowIndex) => (
                              <TableRow key={rowIndex}>
                                {row.cells.map((cell: any, cellIndex: any) => (
                                  <TableCell key={cellIndex} sx={{ minWidth: '150px' }}>
                                    <Controller
                                      control={control}
                                      // @ts-ignore
                                      name={`rows[${rowIndex}].cells[${cellIndex}]`}
                                      render={({ field }) => (
                                        <TextField {...field} variant="outlined" fullWidth />
                                      )}
                                    />
                                  </TableCell>
                                ))}
                                <TableCell sx={{ width: isMobile ? 1 : 0.2, minWidth: '150px' }}>
                                  {items[rowIndex] && items[rowIndex].images.length > 0 ? (
                                    items[rowIndex].images.map((image: any, imageIndex) => (
                                      <Box key={imageIndex} sx={{ position: 'relative' }}>
                                        {!image.edit ? (
                                          <Image src={URL.createObjectURL(image)} width={1} />
                                        ) : (
                                          <Image
                                            src={`https://drive.b24online.com/public-assets/reports-images/${image.path}`}
                                            width={1}
                                          />
                                        )}
                                        <IconButton
                                          size="small"
                                          sx={{ position: 'absolute', top: 0, right: 0 }}
                                          onClick={() =>
                                            handleRemoveImage(rowIndex, imageIndex, items[rowIndex])
                                          }
                                        >
                                          <Iconify icon="simple-line-icons:close" width={20} />
                                        </IconButton>
                                      </Box>
                                    ))
                                  ) : (
                                    <UploadBox
                                      multiple={false}
                                      thumbnail
                                      onDrop={(files) => handleLoad(files, rowIndex)}
                                      onUpload={() => console.info('ON UPLOAD')}
                                    />
                                  )}
                                </TableCell>

                                <TableCell>
                                  <IconButton onClick={() => removeTableRow(rowIndex)}>
                                    <Iconify icon="tabler:trash" width={20} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Divider sx={{ mb: 1 }} />
                  </Box>
                )}
                {item && item?.type === 'assign' && (
                  <>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {isMobile ? (
                        <Iconify
                          sx={{ color: 'red' }}
                          icon="tabler:trash"
                          onClick={() => removeAssignBlock(item.id)}
                        />
                      ) : (
                        <Button sx={{ color: 'red' }} onClick={() => removeAssignBlock(item.id)}>
                          {t('Remove')}
                        </Button>
                      )}
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        {t('Assign')}
                      </Typography>
                    </Stack>
                    <Stack
                      my={1}
                      direction="row-reverse"
                      alignItems="center"
                      gap={1}
                      justifyContent="space-between"
                      width={1}
                    >
                      <Stack
                        key={item.id}
                        direction="row-reverse"
                        alignItems="center"
                        gap={1}
                        width={1}
                        mb={2}
                      >
                        <Autocomplete
                          key={`assigned_${item.id}`}
                          fullWidth
                          options={localCustomers}
                          value={
                            localCustomers?.find(
                              (с: { id: string }) =>
                                с.id === listItems.find((i) => i.id === item.id)?.assigned
                            ) || null
                          }
                          getOptionLabel={(option: any) =>
                            `${option?.firstName} ${option?.lastName} (${option?.email})`
                          }
                          onChange={(event, newValue: any) => {
                            handleBlockAssignChange(item.id, newValue || []);
                          }}
                          renderInput={(params) => {
                            const assign =
                              listItems.find((list) => list.id === item.id)?.assigned || '';
                            return (
                              <TextField
                                {...params}
                                label={t('Assign')}
                                sx={{ width: '100%' }}
                                // error={assign === ''}
                                // helperText={assign === '' ? (
                                //   <Typography sx={{ fontSize: '12px', mt: '-10px' }}>
                                //     {`${t('Assign is required')}`}
                                //   </Typography>
                                // ) : ''}
                              />
                            );
                          }}
                        />

                        <DatePicker
                          sx={{ width: '50%' }}
                          name={`dateToFinish${item.id}`}
                          label={t('Date to finish')}
                          // @ts-ignore
                          value={item?.dateToFinish ? dayjs(item.dateToFinish) : null}
                          onChange={(date) => handleChange(item.id, 'dateToFinish', date)}
                        />
                        <TextField
                          select
                          label={t('Assign')}
                          sx={{ width: '50%' }}
                          // error={listItems.find((list) => list.id === item.id)?.status === ''}
                          // helperText={listItems.find((list) => list.id === item.id)?.status === '' ? <Typography sx={{
                          //   fontSize: '12px',
                          //   mt: '-10px',
                          // }}>{`${t('Status is required')}`}</Typography> : ''}
                          defaultValue={item?.status}
                          onChange={(e) => handleChange(item.id, 'status', e.target.value)}
                        >
                          {['New', 'Open', 'In progress', 'Done'].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                    </Stack>
                    <Divider sx={{ mb: 1 }} />
                  </>
                )}
                {item && item?.type === 'signature' && (
                  <>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {isMobile ? (
                        <Iconify
                          sx={{ color: 'red' }}
                          icon="tabler:trash"
                          onClick={() => removeSignatureBlock(item.id)}
                        />
                      ) : (
                        <Button sx={{ color: 'red' }} onClick={() => removeSignatureBlock(item.id)}>
                          {t('Remove')}
                        </Button>
                      )}
                      <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '16px' }}>
                        {t('Signature')}
                      </Typography>
                    </Stack>
                    <Stack width={1}>
                      <Stack
                        my={1}
                        direction="row-reverse"
                        alignItems="center"
                        gap={1}
                        justifyContent="space-between"
                      >
                        <Stack
                          direction="row-reverse"
                          alignItems="center"
                          gap={1}
                          sx={{ position: 'relative' }}
                        >
                          <Box
                            sx={{ border: '1px solid black', position: 'relative' }}
                            onMouseLeave={() => handleMouseLeave(item)}
                          >
                            <IconButton
                              size="small"
                              sx={{ position: 'absolute' }}
                              onClick={() => clearSignature(item)}
                            >
                              <Iconify icon="mingcute:close-line" width={20} />
                            </IconButton>
                            <SignatureCanvas
                              ref={(ref) => {
                                sigCanvas.current[item.id] = ref || null;
                              }}
                              penColor="black"
                              canvasProps={{
                                width: isMobile ? 300 : 500,
                                height: 200,
                                className: 'sigCanvas',
                              }}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                      <Stack gap={1}>
                        <RHFTextField
                          name={`whoseSignature${item.id}`}
                          select
                          fullWidth
                          value={signatureData.find((data) => data.id === item.id)?.whose || ''}
                          onChange={(event) => handleWhoseSignatureChange(item, event.target.value)}
                        >
                          {['client', 'assign'].map((option: string) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{
                                mx: 1,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </RHFTextField>

                        <Autocomplete
                          fullWidth
                          key={`canvas${item.id}`}
                          options={localCustomers}
                          value={getSelectedCustomer(item.id)}
                          getOptionLabel={(option: any) =>
                            `${option.firstName} ${option.lastName} (${option.email})`
                          }
                          onChange={(event, newValue) => handleNameSignatureChange(item, newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label="Assign" sx={{ width: '100%' }} />
                          )}
                        />
                      </Stack>
                      {isMobile && (
                        <Button
                          variant="contained"
                          color="inherit"
                          onClick={() => handleMouseLeave(item)}
                          sx={{ my: 1 }}
                        >
                          Save
                        </Button>
                      )}
                    </Stack>
                    <Divider sx={{ mb: 1 }} />
                  </>
                )}
                {item && item?.type === 'product' && (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '16px',
                        }}
                      >
                        {t('Product')}
                      </Typography>
                      <Stack direction="row">
                        {isMobile ? (
                          <Iconify
                            sx={{ color: 'green' }}
                            icon="ic:twotone-plus"
                            onClick={() => addProductFieldBlock(item.id)}
                          />
                        ) : (
                          <Button
                            sx={{ color: 'green' }}
                            onClick={() => addProductFieldBlock(item.id)}
                          >
                            {t('Add')}
                          </Button>
                        )}
                        {isMobile ? (
                          <Iconify
                            sx={{ color: 'red' }}
                            icon="tabler:trash"
                            onClick={() => removeProductBlock(item.id)}
                          />
                        ) : (
                          <Button sx={{ color: 'red' }} onClick={() => removeProductBlock(item.id)}>
                            {t('Remove')}
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                    <Stack>
                      {item.blocks.map((i) => (
                        <Stack
                          key={i.id}
                          my={1}
                          direction="row"
                          alignItems="center"
                          gap={1}
                          sx={{ position: 'relative' }}
                        >
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: '-15px',
                              left: '-13px',
                            }}
                            onClick={() => deleteProductFieldBlock(item.id, i.id)}
                          >
                            <Iconify icon="mingcute:close-line" width={20} />
                          </IconButton>
                          <Autocomplete
                            fullWidth
                            options={products.map((option: Ib2bProduct) => ({
                              ...option,
                              key: `${option.id.toString()}-${option.name}`,
                            }))}
                            value={getCurrentProduct(i.productId)}
                            getOptionLabel={(option: any) => option.title}
                            onChange={(event, value) => onChangeSelect(event, value, item.id, i.id)}
                            renderInput={(params) => {
                              const productId =
                                listItems.find((list) => list.id === item.id)?.blocks?.[0]
                                  ?.productId || '';
                              return (
                                <TextField
                                  {...params}
                                  label={t('Product')}
                                  error={productId === ''}
                                  helperText={
                                    productId === '' ? (
                                      <Typography
                                        sx={{
                                          fontSize: '12px',
                                          mt: '-10px',
                                        }}
                                      >{`${t('Product is required')}`}</Typography>
                                    ) : (
                                      ''
                                    )
                                  }
                                />
                              );
                            }}
                            sx={{
                              typography: 'body2',
                              '& .MuiFormLabel-root': { mt: '5px' },
                              '& .MuiInputBase-root': { height: '52px' },
                            }}
                          />
                          <RHFTextField
                            name={`product_${item.id}_${i.id}`}
                            fullWidth
                            value={i.text}
                            label={t('Add text')}
                            onChange={(e) =>
                              handleChangeText(item.id, i.id, 'text', e.target.value)
                            }
                            error={
                              listItems.find((list) => list.id === item.id)?.blocks?.[0]?.text ===
                              ''
                            }
                            helperText={
                              listItems.find((list) => list.id === item.id)?.blocks?.[0]?.text ===
                              '' ? (
                                <Typography
                                  sx={{ fontSize: '12px', mt: '-10px' }}
                                >{`${t('Text is required')}`}</Typography>
                              ) : (
                                ''
                              )
                            }
                          />
                          <RHFTextField
                            name={`count_${item.id}_${i.id}`}
                            sx={{ width: '50%' }}
                            label={t('Count')}
                            value={i.count}
                            onChange={(e) =>
                              handleChangeCount(item.id, i.id, 'count', e.target.value)
                            }
                            error={
                              listItems.find((list) => list.id === item.id)?.blocks?.[0]?.count ===
                              ''
                            }
                            helperText={
                              listItems.find((list) => list.id === item.id)?.blocks?.[0]?.count ===
                              '' ? (
                                <Typography
                                  sx={{ fontSize: '12px', mt: '-10px' }}
                                >{`${t('Text is required')}`}</Typography>
                              ) : (
                                ''
                              )
                            }
                          />
                          <Typography width={isMobile ? 0.5 : 0.2}>
                            {i?.price ? `₪${i?.price}` : '₪'}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" width={1}>
                      <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                        {t('Total')}:
                      </Typography>
                      <Stack direction="row" gap={4}>
                        <Typography> {calculateTotalCount(item.blocks).toFixed(0)}</Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {' '}
                          ₪{calculateTotal(item.blocks).toFixed(2)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider sx={{ mb: 1, mt: 2 }} />
                  </>
                )}
              </Stack>
            ))}
          </LocalizationProvider>
          <Box sx={{ position: 'relative', zIndex: 0 }}>
            <Fab
              color="secondary"
              sx={{ width: isMobile ? '50px' : '50px', height: isMobile ? '50px' : '50px' }}
              aria-label="add"
              onClick={handleClick}
            >
              <Iconify sx={{ color: 'white' }} icon="ic:twotone-plus" onClick={handleClick} />
            </Fab>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <IconButton>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="pajamas:assignee"
                  onClick={() => addAssignBlock()}
                />
              </IconButton>
              <IconButton disabled={tableExists}>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="mingcute:text-2-fill"
                  onClick={() => addTableBlock()}
                />
              </IconButton>
              <IconButton>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="ph:signature-bold"
                  onClick={() => addSignatureBlock()}
                />
              </IconButton>
              {/* {block?.settings?.enableProducts && ( */}
              <IconButton disabled={productsExists}>
                <Iconify
                  sx={{ width: '50px', height: '50px' }}
                  icon="ion:cart"
                  onClick={() => addProductBlock()}
                />
              </IconButton>
              {/* )} */}
            </Menu>
          </Box>
          <Stack direction="row" width={1} gap={1} mt={2}>
            <TextField
              value={leftFooter}
              fullWidth
              label={t('Left footer')}
              name="leftFooter"
              onChange={(e) => setLeftFooter(e.target.value)}
            />
            <TextField
              value={rightFooter}
              fullWidth
              label={t('Right footer')}
              name="rightFooter"
              onChange={(e) => setRightFooter(e.target.value)}
            />
          </Stack>
          <Stack gap={1}>
            <LoadingButton
              fullWidth
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                mt: 1,
                borderRadius: 5,
                bgcolor: 'primary.main',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                },
              }}
            >
              {`${t('View')}`}
            </LoadingButton>
            {loading ? (
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                variant="contained"
                loading={loading}
                onClick={() => savePdf()}
                sx={{
                  mt: 1,
                  borderRadius: 5,
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'primary.main',
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
                  },
                }}
              >
                Loading...
              </LoadingButton>
            ) : (
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                // type="submit"
                variant="contained"
                loading={loading}
                disabled={localData === ''}
                onClick={() => loadPdf()}
                sx={{
                  mt: 1,
                  borderRadius: 5,
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'primary.main',
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
                  },
                }}
              >
                {`${t('Save PDF')}`}
              </LoadingButton>
            )}
          </Stack>
        </FormProvider>
      </Stack>
      <Stack>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 600, width: 1, textAlign: 'center', my: 2 }}
        >
          {t('Document preview')}
        </Typography>
        {headerComponent(headerRef, headerImage, headerText)}
        {PdfComponent({
          localData,
          signatureItems,
          assignedItems,
          tableItems,
          productItems,
          itemToText,
          itemAssignText,
          freeText,
          themeName,
          divToPrintRef,
        })}
        {footerComponent(footerRef, rightFooter, leftFooter)}
      </Stack>
    </Stack>
  );
}
type Props = {
  localData: any;
  signatureItems: any;
  assignedItems: any;
  tableItems: any;
  productItems: any;
  itemToText: any;
  itemAssignText: any;
  freeText: any;
  themeName: any;
  divToPrintRef: any;
};

const PdfComponent = ({
  localData,
  signatureItems,
  assignedItems,
  tableItems,
  productItems,
  itemToText,
  itemAssignText,
  freeText,
  themeName,
  divToPrintRef,
}: Props) => {
  const { t } = useTranslate();
  const calculateTotal = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      const price = parseFloat(it.price as string) || 0;
      return sum + count * price;
    }, 0);
  const isMobile = useResponsive('down', 'sm');
  const calculateTotalCount = (blocks: block[]) =>
    blocks.reduce((sum, it) => {
      const count = parseFloat(it.count as string) || 0;
      return sum + count;
    }, 0);
  return (
    <Stack
      ref={divToPrintRef}
      id="divToPrint"
      mt={4}
      sx={{
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        zIndex: 0,
      }}
    >
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack mx={2}>
          <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }}>
            {itemToText || ''}
          </Typography>
          <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }}>
            {itemAssignText || ''}
          </Typography>
          {freeText?.map((it: any) => (
            <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }} key={it.id}>
              {it.text || ''}
            </Typography>
          ))}
        </Stack>
        <Typography sx={{ fontSize: { md: '25px', xs: '8px' }, fontWeight: 600 }} mx={2}>
          {dayjs().format('DD.MM.YYYY') || ''}
        </Typography>
      </Stack>
      <Typography
        sx={{
          textAlign: 'center',
          mt: 3,
          fontSize: { md: '25px', xs: '12px' },
          fontWeight: 600,
          height: '10mm',
          width: '100mm',
        }}
      >
        {themeName || ''}
      </Typography>
      {!!tableItems.length && (
        <TableContainer>
          <Table sx={{ border: '1px solid black' }}>
            <TableHead sx={{ bgcolor: 'rgba(128, 128, 128, 0.5)' }}>
              <TableRow sx={{ border: '1px solid black' }}>
                {localData?.columns?.map((column: any, colIndex: any) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      border: '1px solid black!important',
                      width: '20%',
                      fontSize: { md: '25px', xs: '8px' },
                      height: { md: '100%', xs: '10%' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.name || ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {localData?.rows?.map((row: any, rowIndex: any) => (
                <TableRow key={rowIndex}>
                  {row?.cells?.map((cell: any, cellIndex: any) => (
                    <TableCell
                      key={cellIndex}
                      sx={{
                        border: '1px solid black!important',
                        width: '20%',
                        fontSize: { md: '25px', xs: '8px' },
                        height: { md: '100%', xs: '10%' },
                        padding: { md: 2, xs: '0px 16px' },
                      }}
                    >
                      {cell || ''}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '30%' },
                      height: { md: '100%', xs: '10%' },
                      fontSize: { md: '25px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {row?.images?.map(
                      (
                        image: Blob | MediaSource | any,
                        imageIndex: React.Key | null | undefined
                      ) => (
                        <>
                          {!image.edit ? (
                            <Image
                              key={imageIndex}
                              src={URL.createObjectURL(image) || ''}
                              sx={{ width: { md: '100%', xs: '100%' } }}
                            />
                          ) : (
                            <Image
                              src={`https://drive.b24online.com/public-assets/reports-images/${image.path}`}
                              width={1}
                            />
                          )}
                        </>
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!!assignedItems.length && (
        <Stack width={1} direction="row" sx={{ my: { md: 3, xs: 1 } }}>
          {assignedItems?.map((it: any) => (
            <Stack key={it.id} width={1} mx={2} sx={{ gap: { md: 3, xs: 0 } }}>
              <Stack direction="row" mt={2}>
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {t('Assigned')} :
                </Typography>
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {it?.text || ''}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {t('Status')} :
                </Typography>
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {it?.status || ''}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography sx={{ fontSize: { md: '25px', xs: '8px' } }}>
                  {t('Date to finish')} :
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      md: '25px',
                      xs: '8px',
                    },
                  }}
                >
                  {(it?.dateToFinish && it?.dateToFinish?.format('DD.MM.YYYY')) || ''}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
      {!!productItems.length && (
        <>
          {productItems?.map((i: any) => (
            <Table style={{ marginTop: isMobile ? '10px' : '40px' }}>
              {i?.blocks?.map((column: any, colIndex: any) => (
                <TableRow key={colIndex}>
                  <TableCell
                    sx={{
                      width: '70%',
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.name || ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '10%' },
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.text || ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '10%' },
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {column.count || ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: { md: '20%', xs: '10%' },
                      fontSize: { md: '18px', xs: '8px' },
                      padding: { md: 2, xs: '0px 16px' },
                    }}
                  >
                    {`₪${column.price || ''}`}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  sx={{ width: { md: '20%', xs: '10%' }, padding: { md: 2, xs: '0px 16px' } }}
                >
                  <Typography sx={{ fontSize: { md: '18px', xs: '8px' }, fontWeight: 600 }}>
                    Totlal :
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell
                  sx={{ width: { md: '20%', xs: '10%' }, padding: { md: 2, xs: '0px 16px' } }}
                >
                  <Typography sx={{ fontSize: { md: '18px', xs: '8px' }, fontWeight: 600 }}>
                    {calculateTotalCount(productItems[0]?.blocks) || ''}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ width: { md: '20%', xs: '10%' }, padding: { md: 2, xs: '0px 16px' } }}
                >
                  <Typography sx={{ fontSize: { md: '18px', xs: '8px' }, fontWeight: 600 }}>
                    {`₪${calculateTotal(productItems[0]?.blocks) || ''}`}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          ))}
        </>
      )}
      {signatureItems.length > 0 && (
        <Stack direction="row" width={1}>
          {signatureItems?.map((it: any) => (
            <Stack key={it.id} width={1} alignItems="center">
              {it.signature && (
                <Image src={it.signature || ''} sx={{ width: { md: '50%', xs: '25%' } }} />
              )}
              <Typography
                sx={{
                  fontSize: {
                    md: '25px',
                    xs: '8px',
                  },
                }}
              >
                {it.name || ''}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const footerComponent = (footerRef: any, rightFooter: string, leftFooter: string) => (
  <Box ref={footerRef} width={1} sx={{ zIndex: 0, height: { md: '15mm', xs: '5mm' } }}>
    <Divider />
    <Stack direction="row" width={1} height={1} justifyContent="space-between">
      <Typography mx={2} sx={{ fontSize: { md: '25px', xs: '8px' } }}>
        {rightFooter || ''}
      </Typography>
      <Typography mx={2} sx={{ fontSize: { md: '25px', xs: '8px' } }}>
        {leftFooter || ''}
      </Typography>
    </Stack>
  </Box>
);

const headerComponent = (headerRef: any, image: string | any, headerText: string) => (
  <Box
    ref={headerRef}
    width={1}
    sx={{
      height: {
        md: '15mm',
        xs: '7mm',
        borderBottom: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
      },
    }}
  >
    <Stack direction="row" width={1} justifyContent="space-between" alignItems="center">
      {headerText && (
        <Typography mx={2} sx={{ fontSize: { md: '20px', xs: '8px' } }}>
          {headerText || ''}
        </Typography>
      )}
      {image && (
        <>
          {!image.edit ? (
            <Image
              src={image ? URL?.createObjectURL(image) : ''}
              sx={{ height: '10mm', img: { height: { md: '10mm', xs: '5mm' } } }}
            />
          ) : (
            <Image
              src={`https://drive.b24online.com/public-assets/reports-images/${image?.path}`}
              sx={{ height: '10mm', img: { height: { md: '10mm', xs: '5mm' } } }}
            />
          )}
        </>
      )}
    </Stack>
  </Box>
);

function TicketsInputName({ sx, ...other }: InputBaseProps) {
  return (
    <InputBase
      sx={{
        [`&.${inputBaseClasses.root}`]: {
          py: 0.75,
          borderRadius: 1,
          typography: 'h6',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'transparent',
          transition: (theme) => theme.transitions.create(['padding-left', 'border-color']),
          [`&.${inputBaseClasses.focused}`]: {
            pl: 1.5,
            borderColor: 'text.primary',
          },
        },
        [`& .${inputBaseClasses.input}`]: {
          typography: 'h6',
        },
        ...sx,
      }}
      {...other}
    />
  );
}

import { FC, useMemo } from 'react';

import { Box, Stack, Button, Typography } from '@mui/material';

import Image from 'src/components/image';

import { useTranslate } from '../../../../locales';
import { getContentValueFromProps } from '../../utils';
import { ButtonField } from '../../../../types/generator';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

const CardsBannerWithButton: FC<ComponentTypeProps> = ({ block }) => {
  const { t } = useTranslate();
  const getFieldValue = getContentValueFromProps(block);
  const { dispatch, state } = useAppContext();
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const title = useMemo(() => {
    if (block?.title) {
      return block?.title;
    }
    return getFieldValue('title');
  }, [block]);

  const mainTitle = useMemo(() => {
    if (block?.mainTitle) {
      return block?.mainTitle;
    }
    return getFieldValue('mainTitle');
  }, [block]);

  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentTextColor'),
    [block?.settings.contentTextColor]
  );
  const contentFontSize = useMemo(
    () => getFieldValue('settings.contentFontSize'),
    [block?.settings.contentFontSize]
  );
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [block?.settings.contentFontWeight]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [block?.settings.contentFontStyle]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [block?.settings.contentTextDecoration]
  );

  const headingTextColor = useMemo(
    () => getFieldValue('settings.headingTextColor'),
    [block?.settings.headingTextColor]
  );
  const headingFontSize = useMemo(
    () => getFieldValue('settings.headingFontSize'),
    [block?.settings.headingFontSize]
  );
  const headingFontWeight = useMemo(
    () => getFieldValue('settings.headingFontWeight'),
    [block?.settings.headingFontWeight]
  );
  const headingFontStyle = useMemo(
    () => getFieldValue('settings.headingFontStyle'),
    [block?.settings.headingFontStyle]
  );
  const headingTextDecoration = useMemo(
    () => getFieldValue('settings.headingTextDecoration'),
    [block?.settings.headingTextDecoration]
  );

  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);

  const enableHeading = useMemo(() => getFieldValue('settings.enableHeading'), [block]);

  const enableTitle = useMemo(() => getFieldValue('settings.enableTitle'), [block]);

  const enableButtons = useMemo(() => getFieldValue('settings.enableButtons'), [block]);

  const enableImages = useMemo(() => getFieldValue('settings.enableImages'), [block]);

  const bannerClasses = useMemo(
    () => getFieldValue('settings.bannerClasses'),
    [block?.settings.bannerClasses]
  );
  const headingClasses = useMemo(
    () => getFieldValue('settings.headingClasses'),
    [block?.settings.headingClasses]
  );
  const contentClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses]
  );
  const imagesClasses = useMemo(
    () => getFieldValue('settings.imagesClasses'),
    [block?.settings.imagesClasses]
  );

  const CARDS = [
    { image: '/assets/images/happy-gift/banners/blackCardImage.png' },
    { image: '/assets/images/happy-gift/banners/blueCardImageWithMargin.png' },
    { image: '/assets/images/happy-gift/banners/redCardImage.png' },
    { image: '/assets/images/happy-gift/banners/whiteCardImage.png' },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      className={bannerClasses}
    >
      {enableHeading && (
        <Typography
          textAlign="center"
          color="primary"
          className={headingClasses}
          sx={{
            direction: forceRtl ? 'rtl' : undefined,
            color: headingTextColor,
            fontSize: headingFontSize,
            fontWeight: headingFontWeight,
            fontStyle: headingFontStyle,
            textDecoration: headingTextDecoration,
            '&:after': { width: { xs: '100%!important' } },
          }}
        >
          {mainTitle}
        </Typography>
      )}
      {enableTitle && (
        <Typography
          textAlign="center"
          className={contentClasses}
          sx={{
            direction: forceRtl ? 'rtl' : undefined,
            color: contentTextColor,
            fontSize: contentFontSize,
            fontWeight: contentFontWeight,
            fontStyle: contentFontStyle,
            textDecoration: contentTextDecoration,
            '&:after': { width: { xs: '100%!important' } },
          }}
        >
          {title}
        </Typography>
      )}
      {enableButtons && (
        <Stack direction="row" spacing={2} py={1} width={1} justifyContent="center">
          {actionButtons.map((actionButton: ButtonField, idx: number) => (
            // @ts-ignore - проблемы с совместимостью типов, не кртичино
            <Button
              variant={actionButton.variant}
              color={actionButton.color}
              size={actionButton.size}
              key={`actionButton_${idx}`}
              sx={{ borderRadius: 10, fontWeight: 400 }}
              href={
                (!actionButton?.link.includes('modal') &&
                  !actionButton.isDialogToggler &&
                  actionButton?.link) ||
                ''
              }
              onClick={() => {
                if (actionButton.isDialogToggler && actionButton.modalId) {
                  setActiveDialog(actionButton.modalId);
                }
              }}
            >
              {actionButton.label || `[${t('no label')}]`}
            </Button>
          ))}
        </Stack>
      )}
      {enableImages && (
        <Box display="flex" mr={10} className={imagesClasses}>
          {CARDS.map((card, ind: number) => (
            <Box
              key={ind}
              mr={-10}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.5)',
                  zIndex: 100,
                },
              }}
            >
              <Image src={card.image} alt="#" />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CardsBannerWithButton;

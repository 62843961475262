import { useParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { FC, useMemo, useState, useEffect } from 'react';

import { Box, Card, Grid, Stack, Button, Typography, ButtonGroup } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { addToBasket } from 'src/api/basket';
import { getProduct } from 'src/api/product';
import { useAuthContext } from 'src/auth/hooks';
import HotIcon from 'src/assets/icons/happy-gift/hotIcon';
import { extractPrices } from 'src/helpers/extractPrices';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

import { Ib2bProduct } from 'src/types/b2b';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import ProductBoomBuyDetailsView from './product-boom-buy-details-view';
import { useContentContext } from '../../../../components/content';
import { useResponsive } from '../../../../hooks/use-responsive';

type ButtonItem = {
  img: string;
  button: any;
};

const HeadBanner: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const getFieldValue = getContentValueFromProps(block);
  const router = useRouter();
  const params = useParams();
  const { t } = useTranslation();
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const [timeLeft, setTimeLeft] = useState(1209600);
  const [currentProduct, setCurrentProduct] = useState({});
  const { cart } = state;
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    localStorage.setItem('change', '');
  }, []);

  const formatTime = (time: any) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return (
      <ButtonGroup
        variant="contained"
        sx={{
          borderRadius: '5px',
          '& .MuiButtonBase-root': {
            borderRadius: '5px',
            backgroundColor: infoBgcolor,
            color: infoColor,
            height: '40px',
            width: '10px',
            alignItems: 'center',
            fontSize: '10px',
            p: 0.5,
            '&:hover': {
              backgroundColor: infoBgcolor,
              color: infoColor,
            },
          },
        }}
      >
        <Button>{hours} שעות</Button>
        <Button>{minutes} דקות</Button>
        <Button>{seconds} שניות</Button>
      </ButtonGroup>
    );
  };

  const firstImage = useMemo(() => getFieldValue('firstImage'), [block?.firstImage]);
  const secondImage = useMemo(() => getFieldValue('secondImage'), [block?.secondImage]);

  // First button settings
  const firstButtonLabel = useMemo(
    () => getFieldValue('firstButtonLabel'),
    [block?.firstButtonLabel]
  );
  const firstButtonHref = useMemo(() => getFieldValue('firstButtonHref'), [block?.firstButtonHref]);
  const firstButtonIcon = useMemo(() => getFieldValue('firstButtonIcon'), [block?.firstButtonIcon]);
  const firstButtonBgColor = useMemo(
    () => getFieldValue('firstButtonBgColor'),
    [block?.firstButtonBgColor]
  );
  const firstButtonColor = useMemo(
    () => getFieldValue('firstButtonColor'),
    [block?.firstButtonColor]
  );
  const firstButtonSize = useMemo(() => getFieldValue('firstButtonSize'), [block?.firstButtonSize]);
  const firstButtonVariant = useMemo(
    () => getFieldValue('firstButtonVariant'),
    [block?.firstButtonVariant]
  );
  const firstButtonDrawer = useMemo(
    () => getFieldValue('firstButtonDrawer'),
    [block?.firstButtonDrawer]
  );
  const firstButtonModal = useMemo(
    () => getFieldValue('firstButtonModal'),
    [block?.firstButtonModal]
  );

  // Second button settings
  const secondButtonLabel = useMemo(
    () => getFieldValue('secondButtonLabel'),
    [block?.secondButtonLabel]
  );
  const secondButtonHref = useMemo(
    () => getFieldValue('secondButtonHref'),
    [block?.secondButtonHref]
  );
  const secondButtonIcon = useMemo(
    () => getFieldValue('secondButtonIcon'),
    [block?.secondButtonIcon]
  );
  const secondButtonBgColor = useMemo(
    () => getFieldValue('secondButtonBgColor'),
    [block?.secondButtonBgColor]
  );
  const secondButtonColor = useMemo(
    () => getFieldValue('secondButtonColor'),
    [block?.secondButtonColor]
  );
  const secondButtonSize = useMemo(
    () => getFieldValue('secondButtonSize'),
    [block?.secondButtonSize]
  );
  const secondButtonVariant = useMemo(
    () => getFieldValue('secondButtonVariant'),
    [block?.secondButtonVariant]
  );
  const secondButtonDrawer = useMemo(
    () => getFieldValue('secondButtonDrawer'),
    [block?.secondButtonDrawer]
  );
  const secondButtonModal = useMemo(
    () => getFieldValue('secondButtonModal'),
    [block?.secondButtonModal]
  );

  const productsButtonLabel = useMemo(
    () => getFieldValue('productsButtonLabel'),
    [block?.productsButtonLabel]
  );
  const productsButtonSize = useMemo(
    () => getFieldValue('productsButtonSize'),
    [block?.productsButtonSize]
  );
  const productsButtonBgColor = useMemo(
    () => getFieldValue('productsButtonBgColor'),
    [block?.productsButtonBgColor]
  );
  const productsButtonColor = useMemo(
    () => getFieldValue('productsButtonColor'),
    [block?.productsButtonColor]
  );
  const enableSecondProductDiscountPrice = useMemo(
    () => getFieldValue('settings.enableSecondProductDiscountPrice'),
    [block?.settings?.enableSecondProductDiscountPrice]
  );
  const topSecondProductPriceColor = useMemo(
    () => getFieldValue('settings.topSecondProductPriceColor'),
    [block?.settings?.topSecondProductPriceColor]
  );
  const bottomSecondProductPriceColor = useMemo(
    () => getFieldValue('settings.bottomSecondProductPriceColor'),
    [block?.settings?.bottomSecondProductPriceColor]
  );
  const productsButtonBorderRadius = useMemo(
    () => getFieldValue('productsButtonBorderRadius'),
    [block?.productsButtonBorderRadius]
  );
  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  const mainProduct = useMemo(() => getFieldValue('mainProduct'), [block?.mainProduct]);
  const virtualCard = useMemo(() => getFieldValue('virtualCard'), [block?.virtualCard]);

  useEffect(() => {
    if (params.title === 'card') {
      getProduct(params.id).then((result: Ib2bProduct) => setCurrentProduct(result));
    } else setCurrentProduct(mainProduct[0]);
  }, []);

  useEffect(() => {
    localStorage.setItem('change', '');
  }, []);

  const secondProducts = useMemo(() => {
    const productsValue = getFieldValue('secondProducts');
    if (Array.isArray(productsValue)) {
      return productsValue.map((category: any) => ({
        ...category,
      }));
    }
    return [];
  }, [block?.secondProducts]);

  const {
    firstGradientColorStop,
    secondGradientColorStop,
    thirdGradientColorStop,
    firstMainGradientColorStop,
    secondMainGradientColorStop,
    thirdMainGradientColorStop,
    firstGradientColor,
    secondGradientColor,
    thirdGradientColor,
    addMainTitleGradientStyle,
    firstMainGradientColor,
    secondMainGradientColor,
    thirdMainGradientColor,
    addGradientStyle,
    variant,
    advertising,
    enableFirstButton,
    enableSecondButton,
    productsTitleTextColor,
    mainTitleTextColor,
    enableTitle,
    productsTitleFontSize,
    productsTitleFontWeight,
    productsTitleFontStyle,
    productsTitleTextDecoration,
    productsAlign,
    enableMainTitle,
    mainTitleFontSize,
    mainTitleFontWeight,
    mainTitleFontStyle,
    mainTitleTextDecoration,
    mainAlign,
    productsContentTitleTextColor,
    productsContentTitleFontSize,
    productsContentTitleFontWeight,
    productsContentTitleFontStyle,
    productsContentTitleTextDecoration,
    productsContentAlign,
    enableHotProductsImage,
    topProductsPriceColor,
    bottomProductsPriceColor,
    hotProductsImageColor,
    hotProductsImageBgcolor,
    enableInfo,
    infoBgcolor,
    infoColor,
    uploadLogo,
    socialLinksColor,
    socialLinksBgcolor,
    socialLinks,
  } = block?.settings || {};

  const items: ButtonItem[] = [
    {
      img: firstImage,
      button: {
        label: firstButtonLabel,
        href: firstButtonHref,
        icon: firstButtonIcon,
        size: firstButtonSize,
        bgcolor: firstButtonBgColor,
        color: firstButtonColor,
        variant: firstButtonVariant,
        isDialogToggler: firstButtonDrawer,
        modalId: firstButtonModal,
        show: enableFirstButton,
      },
    },
    {
      img: secondImage,
      button: {
        label: secondButtonLabel,
        href: secondButtonHref,
        icon: secondButtonIcon,
        size: secondButtonSize,
        bgcolor: secondButtonBgColor,
        color: secondButtonColor,
        variant: secondButtonVariant,
        isDialogToggler: secondButtonDrawer,
        modalId: secondButtonModal,
        show: enableSecondButton,
      },
    },
  ];

  const firstStop = firstGradientColorStop ? `${firstGradientColorStop}%` : '40%';
  const secondStop = secondGradientColorStop ? `${secondGradientColorStop}%` : '80%';
  const thirdStop = thirdGradientColorStop ? `${thirdGradientColorStop}%` : '100%';

  const mainTitleFirstStop = firstMainGradientColorStop ? `${firstMainGradientColorStop}%` : '40%';
  const mainTitleSecondStop = secondMainGradientColorStop
    ? `${secondMainGradientColorStop}%`
    : '80%';
  const mainTitleThirdStop = thirdMainGradientColorStop ? `${thirdMainGradientColorStop}%` : '100%';

  const colorTitle = addGradientStyle
    ? {
        background: `linear-gradient(90deg, ${firstGradientColor} ${firstStop}, ${secondGradientColor} ${secondStop}, ${thirdGradientColor} ${thirdStop} )`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
      }
    : { color: productsTitleTextColor };

  const colorMainTitle = addMainTitleGradientStyle
    ? {
        background: `linear-gradient(90deg, ${firstMainGradientColor} ${mainTitleFirstStop}, ${secondMainGradientColor} ${mainTitleSecondStop}, ${thirdMainGradientColor} ${mainTitleThirdStop} )`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
      }
    : { color: mainTitleTextColor };

  const imageLink = mainProduct[0]?.mainImageURL
    ? `${BASIC_URL_FOR_IMAGES}${mainProduct[0]?.mainImageURL}`
    : '/assets/images/product-default.png';

  return (
    <Grid container spacing={1} direction={variant === 'v1' ? 'row' : 'row-reverse'}>
      <Grid item xs={12} md={9} sx={{ position: 'relative' }}>
        {block?.settings?.imageTitle && (
          <Stack width={1} alignItems="center" sx={{ position: !isMobile ? 'absolute' : '' }}>
            <SvgColor
              src="/assets/images/boom-buy/elements/Zavihren.svg"
              sx={{
                width: '100%',
                height: '50px',
                color: block?.settings?.imageTitleColor,
              }}
            />
            <Typography
              sx={{
                px: 4,
                position: 'absolute',
                direction: 'rtl',
                fontSize: mainTitleFontSize,
                fontWeight: mainTitleFontWeight,
                fontStyle: mainTitleFontStyle,
                textDecoration: mainTitleTextDecoration,
                textAlign: 'center',
                width: '100%',
                ...colorMainTitle,
              }}
            >
              {mainTitle}
            </Typography>
          </Stack>
        )}
        <Stack
          direction="row-reverse"
          alignItems="flex-end"
          justifyContent="space-between"
          sx={{
            right: 0,
            zIndex: 100,
            width: '100%',
          }}
        >
          {enableInfo && <div>{formatTime(timeLeft)}</div>}
          {enableMainTitle && (
            <Typography
              sx={{
                px: 4,
                direction: 'rtl',
                fontSize: mainTitleFontSize,
                fontWeight: mainTitleFontWeight,
                fontStyle: mainTitleFontStyle,
                textDecoration: mainTitleTextDecoration,
                textAlign: mainAlign,
                width: '100%',
                ...colorMainTitle,
              }}
            >
              {mainTitle}
            </Typography>
          )}
        </Stack>
        <ProductBoomBuyDetailsView
          block={block}
          product={block?.addiction === 'products' ? currentProduct : virtualCard[0]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        {advertising === 'banners' && (
          <Stack height="100%">
            {items.map((item, id) => (
              <Box
                key={id}
                sx={{
                  position: 'relative',
                  backgroundPosition: '50%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  // height: '100%',
                }}
              >
                <Stack width={1} alignItems="center" sx={{ position: 'relative' }}>
                  <Image src={item.img} />
                  <Button
                    onClick={() => {
                      if (!user) {
                        dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
                      } else {
                        // addToBasket(
                        //   mainProduct[0].id,
                        //   discountPrice || price,
                        //   imageLink,
                        //   dispatch,
                        //   cart
                        // );
                      }
                    }}
                    variant={item.button.variant || 'contained'}
                    size={item.button.size || 'medium'}
                    href={item.button.href}
                    sx={{
                      display: !item.button.show ? 'none' : '',
                      borderRadius: 10,
                      position: 'absolute',
                      bottom: '30px',
                      color: item.button.color,
                      bgcolor: item.button.bgcolor,
                      '&:hover': {
                        color: item.button.color,
                        bgcolor: item.button.bgcolor,
                      },
                    }}
                    endIcon={<Iconify icon={item?.button.icon || ''} />}
                  >
                    {item.button.label || `[${t('no label')}]`}
                  </Button>
                </Stack>
              </Box>
            ))}
          </Stack>
        )}
        {advertising === 'products' && (
          <Stack>
            {enableTitle && (
              <Typography
                sx={{
                  direction: 'rtl',
                  fontSize: productsTitleFontSize,
                  fontWeight: productsTitleFontWeight,
                  fontStyle: productsTitleFontStyle,
                  textDecoration: productsTitleTextDecoration,
                  textAlign: productsAlign,
                  ...colorTitle,
                }}
              >
                מבצעים חמים לחברים בלבד
              </Typography>
            )}
            <Stack direction="column" gap={1}>
              {secondProducts.slice(0, 2).map((product) => {
                const imageLinkSecond =
                  (product.mainImageURL
                    ? `${uploadProductsImage(`${product.mainImageURL}`)}`
                    : product.merchants &&
                      product.merchants.length &&
                      `${uploadProductsImage(`${product.merchants[0].mainImageURL}`)}`) ||
                  '/assets/images/product-default.png';
                const {
                  basePrice: secondProductDiscountPrice,
                  businessPrice: secondProductBusinessPrice,
                  price: secondProductPrice,
                } = extractPrices(product?.prices);

                const priceToDisplay = secondProductDiscountPrice || secondProductBusinessPrice;
                const secondImageLink = product?.mainImageURL
                  ? `${BASIC_URL_FOR_IMAGES}${product?.mainImageURL}`
                  : '/assets/images/product-default.png';
                return (
                  <Card key={product.id}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                      <Image src={product.brand} sx={{ width: '30%' }} />
                    </Stack>
                    <Stack sx={{ p: 1 }} gap={1}>
                      <Image
                        src={imageLinkSecond}
                        sx={{ height: '180px', cursor: 'pointer' }}
                        onClick={() => {
                          router.push(`/product/${product.id}`);
                        }}
                      />
                      {enableHotProductsImage && (
                        <HotIcon
                          color={hotProductsImageColor}
                          bgcolor={hotProductsImageBgcolor}
                          sx={{
                            position: 'absolute',
                            width: '11%',
                            height: '7%',
                            top: '60px',
                            left: '10px',
                          }}
                        />
                      )}
                      <Typography
                        sx={{
                          color: productsContentTitleTextColor,
                          fontSize: productsContentTitleFontSize,
                          fontWeight: productsContentTitleFontWeight,
                          fontStyle: productsContentTitleFontStyle,
                          textDecoration: productsContentTitleTextDecoration,
                          textAlign: productsContentAlign,
                          height: '50px',
                        }}
                      >
                        {product.title}
                      </Typography>
                      <Stack
                        direction="row-reverse"
                        justifyContent="space-between"
                        gap={3}
                        alignItems="center"
                      >
                        <Button
                          onClick={() => {
                            if (!user) {
                              dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: 'login' });
                            } else {
                              addToBasket(
                                state.smbAccount.id,
                                product.id,
                                (enableSecondProductDiscountPrice && priceToDisplay) ||
                                  secondProductPrice,
                                imageLinkSecond,
                                dispatch,
                                cart,
                                '',
                                []
                              );
                            }
                          }}
                          variant="contained"
                          fullWidth
                          size={productsButtonSize}
                          sx={{
                            borderRadius: productsButtonBorderRadius,
                            color: productsButtonColor,
                            background: productsButtonBgColor,
                            '&:hover': {
                              color: productsButtonColor,
                              background: productsButtonBgColor,
                            },
                          }}
                        >
                          {productsButtonLabel}
                        </Button>
                        {enableSecondProductDiscountPrice && (
                          <Stack direction="column" gap={1} alignItems="center">
                            <Typography
                              sx={{
                                fontSize: '15px',
                                fontWeight: 800,
                                color: topSecondProductPriceColor,
                                textDecoration: 'line-through',
                              }}
                            >
                              ₪{secondProductPrice}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '20px',
                                fontWeight: 1000,
                                color: bottomSecondProductPriceColor,
                              }}
                            >
                              ₪{secondProductDiscountPrice || secondProductBusinessPrice}
                            </Typography>
                          </Stack>
                        )}
                        {!enableSecondProductDiscountPrice && (
                          <Typography
                            sx={{
                              fontSize: '20px',
                              fontWeight: 800,
                              color: topSecondProductPriceColor,
                            }}
                          >
                            ₪{secondProductPrice}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Card>
                );
              })}
            </Stack>
          </Stack>
        )}
        {advertising === 'bigBanner' && (
          <Stack
            sx={{
              height: '97%',
              backgroundImage: 'url(/assets/images/boom-buy/image.png)',
              alignItems: 'start',
              p: 3,
              position: 'relative',
            }}
          >
            <Image src={uploadLogo} sx={{ width: '50%', '& img': { objectFit: 'contain' } }} />
            <Stack direction="row" sx={{ mt: 2 }}>
              <Typography sx={{ color: 'white', fontSize: '24px' }}>ברוך שובך,</Typography>
              <Typography sx={{ color: 'white', fontSize: '24px', fontWeight: 800 }}>
                אוריאל!
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: '30px', fontWeight: 1000, color: 'white', mt: 5 }}>
              תתרגלו לקנות אחרת!
            </Typography>
            <Typography sx={{ fontSize: '18px', color: 'white', mt: 5, width: '70%' }}>
              חווית קניות שאסור לפספס! מוצר חדש ושווה במיוחד בכל יום, במחיר בום.
            </Typography>
            <Typography sx={{ fontSize: '30px', fontWeight: 1000, color: 'white', mt: 5 }}>
              רק ל24 שעות!
            </Typography>
            <Image src="/assets/images/boom-buy/image2.png" sx={{ width: '100%', mt: 2 }} />
            <Stack
              direction="row-reverse"
              alignItems="center"
              justifyContent="space-between"
              // width={isMobile ? 1 : ''}
              sx={{ position: !isMobile ? 'absolute' : '', bottom: '40px', height: '40px' }}
            >
              <SvgColor
                src="/assets/images/boom-buy/frame.svg"
                sx={{
                  color: socialLinksBgcolor,
                  width: '100px',
                  height: '40px',
                  '& img': { objectFit: 'contain' },
                }}
              />
              <Stack
                direction="row"
                justifyContent="flex-start"
                sx={{ mt: 2 }}
                spacing={1}
                height={1}
              >
                {socialLinks?.map((socialLink: any, idx: number) => (
                  <Box
                    key={`socialLinkItem_${idx}`}
                    bgcolor={socialLinksBgcolor}
                    borderRadius={50}
                    width={28}
                    height={28}
                    display="flex"
                    flexDirection="row"
                    // component={Link}
                    // href={socialLink.path}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      ':hover': {
                        bgcolor: socialLinksBgcolor,
                        transition: '.25s ease all',
                        cursor: 'pointer',
                        svg: {
                          color: socialLinksColor,
                        },
                      },
                    }}
                  >
                    <Iconify
                      width={16}
                      height={16}
                      color={socialLinksColor}
                      icon={socialLink.icon ?? 'material-symbols-light:link'}
                    />
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default HeadBanner;

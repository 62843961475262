import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Iconify from '../../../../../../components/iconify';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { useResponsive } from '../../../../../../hooks/use-responsive';

type Item = {
  id: number;
  icon: string;
  title: string;
  text_1: string;
  text_2: string;
};

export const PersonalInformationTab = ({ block }: ComponentTypeProps) => {
  const isMobile = useResponsive('down', 'sm');
  const gridItems: Item[] = [
    {
      id: 1,
      icon: 'tdesign:home',
      title: 'כתובת מגורים',
      text_1: 'הרברט סמואל 33, דירה 33',
      text_2: 'מיקוד 03495',
    },
    {
      id: 2,
      icon: 'mdi:human-handsup',
      title: 'מספר ילדים',
      text_1: '3',
      text_2: '',
    },
    {
      id: 3,
      icon: 'prime:user',
      title: 'מצב משפחתי',
      text_1: 'נשוי',
      text_2: '',
    },
    {
      id: 4,
      icon: 'lucide:calendar',
      title: 'תאריך עלייה',
      text_1: '24/04/1992',
      text_2: '',
    },
  ];

  const mobileBorder = (item: Item) =>
    item.id !== 1 && item.id !== 3 ? '1px solid #c3cfe7' : 'none';
  const desktopBorder = (item: Item) => (item.id !== 1 ? '1px solid #c3cfe7' : 'none');

  return (
    <Stack gap={2}>
      <Stack
        sx={{
          border: '1px solid grey',
          borderRadius: '5px',
          padding: '30px 10px 10px 10px',
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              background: '#c3edff',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>מא</Typography>
          </Box>
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>מוטי מרדכי אברמוב</Typography>
            <Stack direction={isMobile ? 'column' : 'row'} gap={1}>
              <Typography>548557798</Typography>
              {!isMobile && <li style={{ width: '5px' }} />}
              <Typography>24/04/1982</Typography>
              {!isMobile && <li style={{ width: '5px' }} />}
              <Typography>כ` בניסן התשמ``ב</Typography>
              {!isMobile && <li style={{ width: '5px' }} />}
              <Typography> ארץ לידה: אוקראינה</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ my: 2, color: '#c3cfe7' }} />
        <Grid container spacing={1} px={2}>
          {gridItems.map((item) => (
            <Grid
              key={item.id}
              item
              md={3}
              xs={6}
              sx={{
                borderLeft: isMobile ? mobileBorder(item) : desktopBorder(item),
              }}
            >
              <Stack sx={{ px: 1 }}>
                <Stack direction={isMobile ? 'column' : 'row'} gap={1}>
                  <Iconify icon={item.icon} sx={{ color: '#282894' }} />
                  <Typography sx={{ fontWeight: 1000 }}>{item.title}</Typography>
                </Stack>
                <Typography sx={{ fontWeight: 400 }}>{item.text_1}</Typography>
                {item.text_2 && <Typography sx={{ fontWeight: 400 }}>{item.text_2}</Typography>}
              </Stack>
            </Grid>
          ))}
          <Stack direction="row" gap={1} mt={2} px={2} alignItems="center">
            <Iconify
              icon="zondicons:information-solid"
              sx={{ color: '#910537', width: isMobile ? '50px' : 'auto' }}
            />
            <Typography>
              פרטים אלה מגיעים מתוך מרשם האוכלוסין ברשות ואינם ניתנים לעדכון דרך האתר
            </Typography>
          </Stack>
        </Grid>
      </Stack>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          md={7.8}
          xs={12}
          sx={{
            border: '1px solid grey',
            borderRadius: '5px',
            padding: '30px 10px 10px 10px',
          }}
        >
          <Stack direction="row" justifyContent="space-between" height="40px">
            <Stack direction="row" gap={1}>
              <Iconify icon="circum:mail" color="#282894" width={isMobile ? '30px' : 'ato'} />
              <Typography sx={{ fontWeight: 600, fontSize: isMobile ? '12px' : '1rem' }}>
                דרכי התקשרות
              </Typography>
            </Stack>
            <Stack direction="row" gap={1} width={isMobile ? '40%' : 'auto'}>
              <Typography sx={{ fontWeight: 600, fontSize: isMobile ? '12px' : '1rem' }}>
                עדכון דרכי התקשרות
              </Typography>
              <Iconify icon="tdesign:edit" color="#282894" width={isMobile ? '30px' : 'ato'} />
            </Stack>
          </Stack>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>טלפון</Typography>
                <Typography>052-5562314</Typography>
                <Typography>053-8986154</Typography>
              </Stack>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={
                !isMobile
                  ? {
                      borderLeft: '1px solid #c3cfe7',
                      borderRight: '1px solid #c3cfe7',
                    }
                  : {}
              }
            >
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>דואר אלקטרוני</Typography>
                <Typography>exsampleexa@gmail.com</Typography>
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>כתובת למשלוח דואר</Typography>
                <Typography>רחוב הרצל 23, ירושלים</Typography>
                <Typography>293849</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            border: '1px solid grey',
            borderRadius: '5px',
            padding: '30px 10px 10px 10px',
            mt: isMobile ? 2 : 0,
          }}
        >
          <Stack height="40px">
            <Stack direction="row" gap={1}>
              <Iconify icon="mage:id-card" color="#282894" />
              <Typography sx={{ fontWeight: 600 }}>קלפי</Typography>
            </Stack>
            <Typography>עודכן לאחרונה ב:03/09/23</Typography>
          </Stack>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Grid container spacing={1}>
            <Grid item md={4} xs={6}>
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>מספר קלפי</Typography>
                <Typography>124</Typography>
              </Stack>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={
                !isMobile
                  ? {
                      borderLeft: '1px solid #c3cfe7',
                      borderRight: '1px solid #c3cfe7',
                    }
                  : {}
              }
            >
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>סטטוס</Typography>
                <Typography>פעיל</Typography>
              </Stack>
            </Grid>
            <Grid item md={4} xs={6}>
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>מיקום קלפי</Typography>
                <Typography>הרצל 41 א’,</Typography>
                <Typography>רמת גן</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

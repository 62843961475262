import { FC, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { ComponentTypeProps } from 'src/types/page-generator';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { useAuthContext } from 'src/auth/hooks';
import { addLead, leadProps } from 'src/api/lead';

const FORM_TITLE_TEXT = 'דברו איתנו';
const FORM_SUBTITLE_TEXT = 'צרו איתנו קשר בכל נושא, אנחנו כאן בשבילכם באפליקציה ובמספר 3821*';
const SUBMIT_BUTTON_TEXT = 'אישור';
const FIRSTNAME_PLACEHOLDER = 'שם';
const LASTNAME_PLACEHOLDER = 'שם משפחה';
const EMAIL_PLACEHOLDER = 'מייל';
const SUBJECT_PLACEHOLDER = 'נושא הפנייה';
const CONTENT_PLACEHOLDER = 'כתבו כאן את תוכן פנייתכם';
const POPUP_ID = 'contact_us_success';

const ContactCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const { user } = useAuthContext();
  const duplicateSendingEmails = [block?.email_1, block?.email_2, block?.email_3].filter((i) => i);

  const defaultValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    subject: '',
    content: '',
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { dispatch } = useAppContext();
  const methods = useForm({ defaultValues });

  const { reset, handleSubmit, formState, watch } = methods;

  const formValues = watch();

  useEffect(() => {
    const { firstName, lastName, email, subject, content } = formValues;
    setIsSubmitDisabled(!firstName || !lastName || !email || !subject || !content);
  }, [formValues]);

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      dispatch(
        await addLead({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          phone: user?.phone || '',
          message: `Subject: ${data.subject} Text: ${data.content}`,
          duplicateSendingEmails,
        } as leadProps)
      );

      reset();

      setActiveDialog(POPUP_ID);

      setTimeout(() => setActiveDialog(null), 1500 * 20);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Container maxWidth="sm" sx={{ pb: '100px' }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Typography
          variant="h4"
          sx={{ mb: '10px', fontSize: '32px', fontWeight: 400 }}
          align="center"
        >
          {FORM_TITLE_TEXT}
        </Typography>

        <Box sx={{ width: '100%', margin: '0 auto' }}>
          <Typography variant="h6" align="center" sx={{ fontWeight: 400 }}>
            {FORM_SUBTITLE_TEXT}
          </Typography>
        </Box>

        <Box sx={{ mb: '16px', mt: '32px' }}>
          <RHFTextField name="firstName" placeholder={FIRSTNAME_PLACEHOLDER} fullWidth />
        </Box>

        <Box sx={{ mb: '16px' }}>
          <RHFTextField name="lastName" placeholder={LASTNAME_PLACEHOLDER} fullWidth />
        </Box>

        <Box sx={{ mb: '16px' }}>
          <RHFTextField name="email" placeholder={EMAIL_PLACEHOLDER} fullWidth type="email" />
        </Box>

        <Box sx={{ mb: '16px' }}>
          <RHFTextField name="subject" placeholder={SUBJECT_PLACEHOLDER} fullWidth />
        </Box>

        <Box sx={{ mb: '16px' }}>
          <RHFTextField
            name="content"
            placeholder={CONTENT_PLACEHOLDER}
            fullWidth
            multiline
            rows={4}
          />
        </Box>

        <Box sx={{ mb: '16px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ width: '50%' }}
            loading={formState.isSubmitting}
            disabled={isSubmitDisabled}
          >
            {SUBMIT_BUTTON_TEXT}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Container>
  );
};

export default ContactCityPeople;
